import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, Icon, INavLink, INavLinkGroup, Nav, PrimaryButton, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import { AppConstants } from 'components/Utility/Constants';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './MailBodyCSS.scss'
export interface IMailBodyFormProps {
    closeMailWindow: any;
    sendMail: any;
}


export const MailBodyForm: React.FunctionComponent<IMailBodyFormProps> = ({ ...props }) => {
    const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
    let [_showMailWindow, _setMailWindow] = useState<boolean>(false);
    let [_errorMSG, _showErrorMSG] = useState<boolean>(false);
    let [_mailBody, _setMailBody] = useState<string>("");
    useEffect(() => {

    }, []);

    return (
        <>
            <Dialog
                hidden={false}
                onDismiss={props.closeMailWindow}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t('ModalDialogHeader.UserMailToAdmin'),
                }}
                modalProps={{
                    isBlocking: true,
                    className: "mailConfirmationBox",
                    // styles: { main: { width: 500 } },
                }}
            >
                <DialogContent>
                    <Stack>
                        <StackItem>{t('AdminMailDialogForm.Fields.MailBodyText')}:</StackItem>
                        <StackItem>
                            <TextField
                                className='mailBody'
                                multiline={true}
                                autoComplete="off"
                                value={_mailBody}
                                onChange={(ev, text) => {
                                    _setMailBody(text);
                                }}
                            ></TextField>
                            {_errorMSG
                                &&
                                <label className='errorMailBody'>{t('Error.NoMailBodyForUserToAdminMail')}</label>
                            }
                        </StackItem>
                    </Stack>
                </DialogContent>
                <DialogFooter className='mailfooter'>
                    <PrimaryButton onClick={() => {
                        if (_mailBody) {
                            _showErrorMSG(false);
                            props.sendMail(_mailBody);
                        }
                        else {
                            _showErrorMSG(true);
                        }
                    }} text={t('AdminMailDialogForm.ActionText.SendMail')} />
                    <DefaultButton onClick={() => {
                        props.closeMailWindow();
                    }} text={t('AdminMailDialogForm.ActionText.Cancel')} />
                </DialogFooter>
            </Dialog>

        </>
    );
};
