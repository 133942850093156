import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, INavLink, INavLinkGroup, Nav, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import { AppConstants } from 'components/Utility/Constants';
import { Utility } from 'components/Utility/Utility';
import { AppTopContext } from 'components/Welcome/Common/AppTopContext';
import { MailBodyForm } from 'components/Welcome/Common/MailBodyDialog';
import DataProvider from 'dataprovider/DataProvider';
import { IUserFieldsState } from 'Interfaces/IHRBrazil';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
export interface ISubmitProfileMessageProps {
    user: IUserFieldsState;
}


export const SubmitProfileMessage: React.FunctionComponent<ISubmitProfileMessageProps> = ({ ...props }) => {
    var dataProvider = new DataProvider();
    const appContext = useContext(AppTopContext);
    const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);

    let [_showMailWindow, _setMailWindow] = useState<boolean>(false);

    useEffect(() => {
    }, []);

    function closeMailModal() {
        _setMailWindow(false);
    }
    function sendMail(mailbody: string) {
        closeMailModal();
        appContext.ShowAppLoader(true, "");
        dataProvider.sendUserMailToAdmin(appContext.IsLBrazil, props.user.ProfileId, mailbody.replace(/\n/g, "<br>"))
            .then((res) => {
                if (res.hasError) {
                    console.log(res);
                    if (!Utility.IsUnauthorizeError(res.errorDetail, appContext)) {
                        appContext.ShowAppModal(t('ModalDialogHeader.Error'), t('Error.UserMailToAdmin.UserMailToAdminError'));
                    }
                }
                else {
                    appContext.ShowAppModal(t('ModalDialogHeader.Success'), t('Success.UserMailToAdmin.UserMailToAdminSuccess'));
                }
                appContext.ShowAppLoader(false, "");
            })
            .catch(err => {
                appContext.ShowAppModal(t('ModalDialogHeader.Error'), t('Error.UserMailToAdmin.UserMailToAdminCallError'));
                appContext.ShowAppLoader(false, "");
            });
    }
    return (
        <>
            {_showMailWindow &&
                <MailBodyForm
                    closeMailWindow={closeMailModal}
                    sendMail={sendMail}
                />
            }
            {props.user?.Status == AppConstants.UserFilterOptions.Submitted &&
                <div className='submitMSG'>
                    <div>
                        {t('Information.UserProfileStatusMSG.ProfileMSGForSubmittedStatus')}
                    </div>
                    <div>
                        <span className='adminLink' onClick={() => {
                            _setMailWindow(true);
                        }}>
                            {t('Information.UserProfileStatusMSG.ContactToAdminText')}
                        </span>
                    </div>
                </div>
            }
            {props.user?.Status == AppConstants.UserFilterOptions.Draft &&
                <div className='draftMSG'>
                    {t('Information.UserProfileStatusMSG.ProfileMSGForDraftStatus')}
                </div>
            }
        </>
    );
};
