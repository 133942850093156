import React, { Component } from 'react';
import { Layout } from '../components/Layout/Layout.tsx';
import { WelcomePage } from '../components/Welcome/Welcome.tsx';
import { LoginComponent } from '../components/LoginComponent/LoginComponent.tsx';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Common } from '../components/LoginComponent/Common';
import '../custom.scss'
import DataProvider from '../dataprovider/DataProvider';
import { CookieList } from '../components/LoginComponent/CookieList.tsx';
import { AppConstants } from '../components/Utility/Constants';
import '../components/i18n/i18n';
import i18next, { t } from 'i18next';
import { Utility } from './Utility/Utility';
import { Spinner } from '@fluentui/react';


export class Home extends Component {
  refLoginComponent = null;

  constructor() {
    super();
    this.state = {
      aId: null,
      tId: null,
      authenticationData: {
        showGAuthentication: false,
        showMSAuthentication: false,
        showCustom: false,
        msUsersAllowed: []
      },
      isLoggedIn: false,
      currentUser: null,
      path: "/",
      redirectHome: false,
      isLBrazil: i18next.language == AppConstants.languageSupport.ptBR ? true : false
    };
    this.refLoginComponent = React.createRef();
    this.setAppSettings = this.setAppSettings.bind(this);
    this.checkAndLogoutSession = this.checkAndLogoutSession.bind(this);
    this.changeLocalization = this.changeLocalization.bind(this);
  }
  getMSALConfig() {
    let returnURI = Common.getCurrentServerUrl();
    return {
      auth: {
        clientId: this.state.aId,
        authority: "https://login.microsoftonline.com/" + this.state.tId,
        redirectUri: `${returnURI}`
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };
  }
  changeLocalization(isLBrazil) {
    i18next.changeLanguage(isLBrazil ? AppConstants.languageSupport.ptBR : AppConstants.languageSupport.en)
    this.setState({
      isLBrazil: isLBrazil
    }, () => {
      localStorage.setItem(AppConstants.languageStorage, isLBrazil ? AppConstants.languageSupport.ptBR : AppConstants.languageSupport.en);
    });
  }
  setAppSettings(data) {
    this.setState({
      tId: data.tId ? data.tId : "",
      aId: data.aId ? data.aId : "",
      authenticationData: {
        showGAuthentication: data.showGAuthentication,
        showMSAuthentication: data.showMSAuthentication,
        showCustom: data.showCustom,
        msUsersAllowed: data.msUsersAllowed
      }
    }, () => {
      this.checkAndLogoutSession();
    });
  }
  checkAndLogoutSession() {
    if (this.state.currentUser) {
      if (
        (this.state.currentUser.accountType == AppConstants.AccountType.Custom
          && this.state.authenticationData.showCustom == false
        )
        ||
        (this.state.currentUser.accountType == AppConstants.AccountType.Microsoft
          && this.state.authenticationData.showMSAuthentication == false
        )
      ) {
        this.sessionExpired();
      }
    }
  }
  componentDidMount() {
    // in case the page is reloaded, check if there is a user in the session already
    if (window.sessionStorage[AppConstants.Storage.authToken]) {
      let authToken = JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]);
      let expirationDate = new Date(authToken.expirationUTC);
      if (expirationDate < new Date()) {
        // this token is expired. Clean it up
        Utility.CleanSession();
        alert("Your Session expired. Please sign-in again.");
      }
      if (window.sessionStorage[AppConstants.Storage.appSession]) {
        let currentUser = JSON.parse(window.sessionStorage[AppConstants.Storage.appSession]);
        this.setState({
          isLoggedIn: true,
          currentUser: currentUser
        });
      }
      else {
        Utility.CleanSession();
      }
    }
  }

  componentWillReceiveProps(newProps) {
  }
  setAuthStatus = (loggedIn, user) => {
    return new Promise((resolve, reject) => {
      this.setState({ isLoggedIn: loggedIn, currentUser: user }, () => {
        // console.log("%cLOG OUT HERE!!!!!", "color:yellow;font-size:16px");
        if (!user) {
          Utility.CleanSession();
          // redirect to home
          window.location.href = `${window.location.protocol}//${window.location.host}`;
        }
        resolve();
      });
    });
  }

  sessionExpired = () => {
    this.setState({
      isLoggedIn: false,
      currentUser: null
    });
    Utility.CleanSession();
    alert("Your Session expired. Please sign-in again.");
    window.location.replace(window.location.origin);

  };

  dataProvider = new DataProvider();


  render() {
    return (

      <Layout setAppSettings={this.setAppSettings}>
        {this.state.aId != null
          ?
          <>
            {(this.state.aId && this.state.tId)
              ?
              <MsalProvider instance={new PublicClientApplication(this.getMSALConfig())}>
                <div className='locatization'>
                  <span>  <a onClick={() => this.changeLocalization(false)} title='English' href="javascript: void(0)" className={!this.state.isLBrazil ? "selected" : "unselected"}>EN</a>|<a title='Portuguese (Brazil)' href="javascript: void(0)" onClick={() => this.changeLocalization(true)} className={this.state.isLBrazil ? "selected" : "unselected"}>PT-BR</a></span>
                </div>
                {this.state.isLoggedIn
                  ?
                  <WelcomePage
                    {...this.props}
                    isLBrazil={this.state.isLBrazil}
                    dataProvider={this.dataProvider}
                    currentUser={this.state?.currentUser}
                    isLoggedIn={this.state.isLoggedIn}
                    sessionExpired={this.sessionExpired}
                  />
                  :
                  ""
                }

                <LoginComponent
                  authenticationData={this.state.authenticationData}
                  key={this.state.path} ref={this.refLoginComponent}
                  setAuthStatus={this.setAuthStatus}
                  currentUser={this.state?.currentUser} />

              </MsalProvider>
              :
              <div className='appLoadingError'>
                {i18next.t('home:Error.AppConfigError')}
              </div>
            }
          </>
          :
          <div>
            <Spinner label={i18next.t('home:appLoaderMSG')} />
          </div>
        }
      </Layout>
    );
  }
}
