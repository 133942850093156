
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import homeBr from '../../public/locales/pt-Brazil/home.json';
import homeEn from '../../public/locales/en/home.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AppConstants } from 'components/Utility/Constants';
const resources = {
    "en": {
        home: homeEn,
    },
    "pt-BR": {
        home: homeBr,
    }
}

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem(AppConstants.languageStorage) != null
            ? localStorage.getItem(AppConstants.languageStorage)
            : (navigator.language == AppConstants.languageSupport.ptBR ? AppConstants.languageSupport.ptBR : AppConstants.languageSupport.en),
        debug: false,
        fallbackLng: AppConstants.languageSupport.en,
        detection: {
            // Customize the detection options as needed
            // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
            // caches: ['cookie'],
            // lookupQuerystring: 'lng',
            lookupLocalStorage: AppConstants.languageStorage,
            localStorage
        },
        saveMissing: true
    });

export default i18next;