import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  INavLink,
  INavLinkGroup,
  IRenderFunction,
  IRenderGroupHeaderProps,
  Nav,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  IAppChoicesState,
  INavigationActions,
  IUserAdditionalData,
  IUserBasicData,
  IUserDependentInfo,
  IUserDoc1Data,
  IUserDoc2Data,
  IUserFieldsState,
  IUserProfileData,
  IUserViewState,
} from "../../../../Interfaces/IHRBrazil";
import { AppConstants } from "../../../Utility/Constants";
import { AdditionalForm } from "./ProfileComponents/Additional";
import { BasicForm } from "./ProfileComponents/BasicForm";
import { DefaultForm } from "./ProfileComponents/Default";
import { DependentDetailForm } from "./ProfileComponents/DependentDetail";
import { Documents1Form } from "./ProfileComponents/Documents1";
import { Documents2Form } from "./ProfileComponents/Documents2";
import { useTranslation } from "react-i18next";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import { v4 as uuidv4 } from "uuid";
import DataProvider from "dataprovider/DataProvider";
import { useMemo } from "react";
import { SubmitProfileMessage } from "./ProfileComponents/SubmitProfileMessage";
import { Utility } from "components/Utility/Utility";
export interface IUserProfileFormProps {
  itemID?: number;
  cancelPanel?: any;
}

export const UserProfileForm: React.FunctionComponent<IUserProfileFormProps> = ({ ...props }) => {
  var dataProvider = new DataProvider();
  const appContext = useContext(AppTopContext);
  let [_isFormEditable, _setIsFormEditable] = useState<boolean>(!appContext.IsUserAdmin);
  let [_userViewState, _setUserViewState] = useState<IUserViewState>({
    NextKey: "",
    PrevKey: "",
    SelectedKey: AppConstants.SectionKey.BasicData,
  });
  let [_userOriginalData, _setUserOriginalData] = useState<IUserFieldsState>(null);

  let [_userProfileData, _setUserProfileData] = useState<IUserProfileData>({
    BasicData: null,
    AdditionalData: null,
    Doc1: null,
    Doc2: null,
    DependDetail: null,
  });
  let [_navigationAction, _setNavigationAction] = useState<INavigationActions>({
    Guid: "",
    NavigationFun: null,
    Key: "",
  });
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);

  const navLinkGroups: INavLinkGroup[] = [
    {
      name: t("UserProfileOptions.SectionHeader"),
      links: [
        { name: t("UserProfileOptions.BasicData"), url: "", key: "BasicData" },
        { name: t("UserProfileOptions.AdditionalInformation"), url: "", key: "AdditionalData" },
        { name: t("UserProfileOptions.Documents1"), url: "", key: "Documents1" },
        { name: t("UserProfileOptions.Documents2"), url: "", key: "Documents2" },
        { name: t("UserProfileOptions.DependentDetail"), url: "", key: "DependentDetail" },
      ],
    },
  ];

  useEffect(() => {
    setProfileComponent(AppConstants.SectionKey.BasicData);
  }, []);
  useEffect(() => {
    if (props.itemID > 0) {
      GetUserProfileData();
    }
  }, [props.itemID]);

  function GetUserProfileData() {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .getUserProfileByID(props.itemID, false)
      .then((item) => {
        if (item.hasError) {
          console.log(item.errorDetail);
          if (!Utility.IsUnauthorizeError(item.errorDetail, appContext)) {
            appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInGettingUserDataApiResult"));
          }
        } else {
          let initialUserProfileData: IUserProfileData = {
            BasicData: Utility.GetInitialData(AppConstants.SectionKey.BasicData) as IUserBasicData,
            AdditionalData: Utility.GetInitialData(AppConstants.SectionKey.AdditionalData) as IUserAdditionalData,
            Doc1: Utility.GetInitialData(AppConstants.SectionKey.Documents1) as IUserDoc1Data,
            Doc2: Utility.GetInitialData(AppConstants.SectionKey.Documents2) as IUserDoc2Data,
            DependDetail: Utility.GetInitialData(AppConstants.SectionKey.DependentDetail) as IUserDependentInfo[],
          };
          item.data.formData = item.data.formData ? item.data.formData : JSON.stringify(initialUserProfileData);
          let userOriginalData: IUserFieldsState = {
            FirstName: item.data.firstName,
            LastName: item.data.lastName,
            Email: item.data.email.trim().toLowerCase().replaceAll(" ", ""),
            Password: item.data.password.trim().replaceAll(" ", ""),
            HiringDate: item.data.hiringDate,
            Status: item.data.status,
            ProfileId: item.data.profileId,
            FormData: item.data?.formData,
            UserId: item.data.userId,
            Validated: item.data.validated,
          };
          _setUserOriginalData(userOriginalData);

          if (userOriginalData.Status == AppConstants.UserFilterOptions.Submitted) {
            if (appContext.IsUserAdmin && _isFormEditable == true) {
            } else {
              _setIsFormEditable(false);
            }
          }

          try {
            if (item.data.formData) {
              let userProfileData: IUserProfileData = JSON.parse(item.data.formData);
              _setUserProfileData(userProfileData);
            }
          } catch (err) {
            appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.JSONFormatError"));
          }
        }
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInGettingUserDataApiCall"));
        appContext.ShowAppLoader(true, "");
      });
  }
  function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink): void {
    _setNavigationAction({
      Guid: uuidv4(),
      NavigationFun: function () {
        return setProfileComponent(item?.key as string);
      },
      Key: item?.key as string,
    });
  }
  function _onPrevClick(): void {
    setProfileComponent(_userViewState.PrevKey);
  }

  function _onNextClick(): void {
    setProfileComponent(_userViewState.NextKey);
  }

  function setProfileComponent(key: string): void {
    let selectedKey = key;
    let prevKey = "";
    let nextKey = "";
    switch (key) {
      case AppConstants.SectionKey.BasicData:
        prevKey = "";
        nextKey = AppConstants.SectionKey.AdditionalData;
        break;
      case AppConstants.SectionKey.AdditionalData:
        prevKey = AppConstants.SectionKey.BasicData;
        nextKey = AppConstants.SectionKey.Documents1;
        break;
      case AppConstants.SectionKey.Documents1:
        prevKey = AppConstants.SectionKey.AdditionalData;
        nextKey = AppConstants.SectionKey.Documents2;
        break;
      case AppConstants.SectionKey.Documents2:
        prevKey = AppConstants.SectionKey.Documents1;
        nextKey = AppConstants.SectionKey.DependentDetail;
        break;
      case AppConstants.SectionKey.DependentDetail:
        prevKey = AppConstants.SectionKey.Documents2;
        nextKey = "";
        break;
      default:
        prevKey = "";
        nextKey = "";
        break;
    }
    _setNavigationAction({
      Guid: "",
      NavigationFun: null,
      Key: "",
    });
    _setUserViewState({
      NextKey: nextKey,
      PrevKey: prevKey,
      SelectedKey: selectedKey,
    });
    appContext.ShowAppLoader(false, "");
  }

  function RenderProfileComponent(key: string) {
    let element = document.getElementById("root");
    if ((props as any).currentUser.isHR) {
      element = document.getElementById("scrollsection");
    }
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    let componentView;
    switch (key) {
      case AppConstants.SectionKey.BasicData:
        componentView = (
          <BasicForm
            user={_userOriginalData}
            isFormEditable={_isFormEditable}
            updateFormMode={updateFormMode}
            saveUserProfile={saveUserProfile}
            navigationAction={_navigationAction}
            userProfileFormData={_userProfileData?.BasicData ? _userProfileData?.BasicData : null}
            {...props}
            previousCall={undefined}
            submitCall={undefined}
            nextCall={_onNextClick}
          />
        );
        break;
      case AppConstants.SectionKey.AdditionalData:
        componentView = (
          <AdditionalForm
            user={_userOriginalData}
            isFormEditable={_isFormEditable}
            saveUserProfile={saveUserProfile}
            updateFormMode={updateFormMode}
            navigationAction={_navigationAction}
            userProfileFormData={_userProfileData?.AdditionalData ? _userProfileData?.AdditionalData : null}
            {...props}
            previousCall={_onPrevClick}
            nextCall={_onNextClick}
            submitCall={undefined}
          />
        );
        break;
      case AppConstants.SectionKey.Documents1:
        componentView = (
          <Documents1Form
            user={_userOriginalData}
            isFormEditable={_isFormEditable}
            saveUserProfile={saveUserProfile}
            updateFormMode={updateFormMode}
            navigationAction={_navigationAction}
            userProfileFormData={_userProfileData?.Doc1 ? _userProfileData?.Doc1 : null}
            {...props}
            previousCall={_onPrevClick}
            nextCall={_onNextClick}
            submitCall={undefined}
          />
        );
        break;
      case AppConstants.SectionKey.Documents2:
        componentView = (
          <Documents2Form
            user={_userOriginalData}
            isFormEditable={_isFormEditable}
            saveUserProfile={saveUserProfile}
            updateFormMode={updateFormMode}
            navigationAction={_navigationAction}
            userProfileData={_userProfileData}
            userProfileFormData={_userProfileData?.Doc2 ? _userProfileData?.Doc2 : null}
            {...props}
            previousCall={_onPrevClick}
            nextCall={_onNextClick}
            submitCall={undefined}
          />
        );
        break;
      case AppConstants.SectionKey.DependentDetail:
        componentView = (
          <DependentDetailForm
            user={_userOriginalData}
            isFormEditable={_isFormEditable}
            saveUserProfile={saveUserProfile}
            updateFormMode={updateFormMode}
            navigationAction={_navigationAction}
            userProfileFormData={_userProfileData?.DependDetail ? _userProfileData?.DependDetail : []}
            {...props}
            previousCall={_onPrevClick}
            nextCall={undefined}
            submitCall={_onSubmit}
          />
        );
        break;
      default:
        componentView = <DefaultForm />;
        break;
    }
    return componentView;
  }

  function saveUserProfile(sectionKey: string, data: any, updateData: boolean, callFunc: any) {
    let validatedErrorForm = "";
    /// validation during data save
    let userProfileData = JSON.parse(JSON.stringify(_userProfileData)) as IUserProfileData;
    switch (sectionKey) {
      case AppConstants.SectionKey.BasicData:
        if (appContext.IsUserAdmin) {
          validatedErrorForm = Utility.ValidationWithErrorForm(AppConstants.SectionKey.BasicData, data, userProfileData);
        }
        userProfileData.BasicData = data as IUserBasicData;
        break;
      case AppConstants.SectionKey.AdditionalData:
        if (appContext.IsUserAdmin) {
          validatedErrorForm = Utility.ValidationWithErrorForm(AppConstants.SectionKey.AdditionalData, data, userProfileData);
        }
        userProfileData.AdditionalData = data as IUserAdditionalData;
        break;
      case AppConstants.SectionKey.Documents1:
        if (appContext.IsUserAdmin) {
          validatedErrorForm = Utility.ValidationWithErrorForm(AppConstants.SectionKey.Documents1, data, userProfileData);
        }
        userProfileData.Doc1 = data as IUserDoc1Data;
        break;
      case AppConstants.SectionKey.Documents2:
        if (appContext.IsUserAdmin) {
          validatedErrorForm = Utility.ValidationWithErrorForm(AppConstants.SectionKey.Documents2, data, userProfileData);
        }
        userProfileData.Doc2 = data as IUserDoc2Data;
        break;
      case AppConstants.SectionKey.DependentDetail:
        userProfileData.DependDetail = data as IUserDependentInfo[];
        break;
      default:
        break;
    }
    if (validatedErrorForm === "") {
      let userProfileFormat = Utility.GetUserProfileFormat(userProfileData);
      _setUserProfileData(userProfileFormat.UserProfile);

      let userData: IUserFieldsState = {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        Status: "",
        ProfileId: props.itemID,
        FormData: JSON.stringify(userProfileFormat.UserProfile),
        UserId: _userOriginalData.UserId,
        Validated: _userOriginalData.Validated,
        HiringDate: undefined,
      };
      appContext.ShowAppLoader(true, "");
      dataProvider
        .updateUserProfile(appContext.IsLBrazil, userData, userProfileFormat.UserDocs)
        .then((output) => {
          if (output.hasError) {
            console.log(output);
            if (!Utility.IsUnauthorizeError(output.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInUpdatingUserProfileDataApiResult"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            if (callFunc != null) {
              appContext.ShowAppModalWithFun(t("ModalDialogHeader.Success"), t("Success.ProfileMSG.ProfileSaveMSG"), callFunc);
            } else {
              appContext.ShowAppModal(t("ModalDialogHeader.Success"), t("Success.ProfileMSG.ProfileSaveMSG"));
            }
            GetUserProfileData();
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInUpdatingUserProfileDataApiCall"));
          appContext.ShowAppLoader(true, "");
        });
    } else {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
      appContext.ShowAppLoader(false, "");
    }
  }

  function _onSubmit(): void {
    /// validate data before submit
    let validatedErrorForm = Utility.ValidationWithErrorForm(AppConstants.SectionKey.AllFormData, _userProfileData, _userProfileData);
    if (validatedErrorForm === "") {
      let userProfileFormat = Utility.GetUserProfileFormat(_userProfileData);
      let userData: IUserFieldsState = {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        Status: AppConstants.UserFilterOptions.Submitted,
        ProfileId: props.itemID,
        FormData: JSON.stringify(userProfileFormat.UserProfile),
        UserId: _userOriginalData.UserId,
        Validated: _userOriginalData.Validated,
        HiringDate: undefined,
      };
      appContext.ShowAppLoader(true, "");
      dataProvider
        .updateUserProfile(appContext.IsLBrazil, userData, userProfileFormat.UserDocs)
        .then((output) => {
          if (output.hasError) {
            console.log(output);
            if (!Utility.IsUnauthorizeError(output.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInUpdatingUserProfileDataApiResult"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            appContext.ShowAppModal(t("ModalDialogHeader.Success"), t("Success.ProfileMSG.ProfileSubmitMSG"));
            GetUserProfileData();
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInUpdatingUserProfileDataApiCall"));
          appContext.ShowAppLoader(true, "");
        });
    } else {
      appContext.ShowAppModal(
        t("ModalDialogHeader.ValidationMessage"),
        t("ValidationMessage.FormRequiredFieldValidation").replace("{formname}", validatedErrorForm)
      );
    }
  }

  function updateFormMode(formMode: boolean) {
    _setIsFormEditable(formMode);
  }
  const _memoizedResult = useMemo(() => {
    return (
      <>
        {/* {"Test Time to check memo effect" + (new Date()).toLocaleTimeString()} */}
        {!appContext.IsUserAdmin && _userOriginalData && (
          <Stack className="submitMessage">
            <SubmitProfileMessage user={_userOriginalData} />
          </Stack>
        )}
        <Stack className="topScrollDiv">
          <Stack id="scrollsection" className="userProfileSection" horizontal={true}>
            <StackItem className="userProfileNavigation">
              <Nav
                selectedKey={_userViewState.SelectedKey}
                onRenderGroupHeader={_onRenderGroupHeader as IRenderFunction<IRenderGroupHeaderProps>}
                ariaLabel="Nav with custom group headers"
                groups={navLinkGroups}
                onLinkClick={_onLinkClick}
              />
            </StackItem>
            <StackItem className="userProfileForm">{RenderProfileComponent(_userViewState.SelectedKey)}</StackItem>
          </Stack>
        </Stack>
      </>
    );
  }, [props.itemID, _userViewState, _isFormEditable, _navigationAction.Guid, _userProfileData, _userOriginalData, appContext.IsLBrazil]);
  return <>{_memoizedResult}</>;
  function _onRenderGroupHeader(group: INavLinkGroup): JSX.Element {
    return <label className="secHeader">{group.name}</label>;
  }
};
UserProfileForm.defaultProps = {
  itemID: 0,
  cancelPanel: undefined,
};
