import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  IDropdownOption,
  INavLink,
  INavLinkGroup,
  Nav,
  Panel,
  PanelType,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { AttachmentComponent, IFileInfo } from "ccs-azure";
import { DependentUserFormDLLEnum, DependentUserFormTXTEnum, ValidationType } from "components/Utility/AppEnums";
import { AppConstants } from "components/Utility/Constants";
import { Utility } from "components/Utility/Utility";
import { AppCalendar } from "components/Welcome/Common/AppCalendar";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import { FileSizeMSG } from "components/Welcome/Common/FileSizeMSG";
import DataProvider from "dataprovider/DataProvider";
import { IAppFileInfo, IUserDependentInfo, IUserDependentPanelState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./UserDependentForm.scss";
export interface IUserDependentFormProps {
  showDependentForm: any;
  saveAndShowDependentForm: (userInfo: IUserDependentInfo, isNewDependent: boolean) => void;
  isFormEditable: boolean;
  userDependentData: IUserDependentInfo;
  actionBTNText: string;
}

export const UserDependentForm: React.FunctionComponent<IUserDependentFormProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  const appContext = useContext(AppTopContext);
  var dataProvider = new DataProvider();

  let [_isFileUpdated, _setIsFileUpdated] = useState<boolean>(false);
  let [_deletedDocs, _setDeletedDocs] = useState<Array<IFileInfo>>([]);
  let [_relationShipOptions, _setRelationShipOptions] = useState<IDropdownOption[]>([]);
  let [_genderOptions, _setGenderOptions] = useState<IDropdownOption[]>([]);
  let [_maritalStatusOptions, _setMaritalStatusOptions] = useState<IDropdownOption[]>([]);
  let [_cpfProofDoc, _setCPFProofDoc] = useState<Array<IFileInfo | File>>([]);
  let [_birthDoc, _setBirthDoc] = useState<Array<IFileInfo | File>>([]);
  let [_identityDoc, _setIdentityDoc] = useState<Array<IFileInfo | File>>([]);
  const [_userDependentInfo, _setUserDependentInfo] = useState<IUserDependentInfo>(
    props.userDependentData ? props.userDependentData : Utility.GetInitialData(AppConstants.SectionKey.UserDependentInfo)
  );
  useEffect(() => {
    var relationShipOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Relation);
    _setRelationShipOptions(relationShipOptions);
    var genderOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Gender);
    _setGenderOptions(genderOptions);
    var maritalStatusOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.MaritalStatus);
    _setMaritalStatusOptions(maritalStatusOptions);
  }, []);
  useEffect(() => {
    let initialData = Utility.GetInitialData(AppConstants.SectionKey.UserDependentInfo, props.userDependentData) as IUserDependentInfo;
    _setBirthDoc(Utility.GetFiles(initialData?.BCertificate));
    _setCPFProofDoc(Utility.GetFiles(initialData?.CPF?.Doc));
    _setIdentityDoc(Utility.GetFiles(initialData?.RG?.Doc));
    _setUserDependentInfo(initialData);
  }, [props.userDependentData]);
  const onFileDeleted =
    (attachmentType: string) =>
    (file: IFileInfo | File): Promise<void> => {
      let attachments = [];
      let deletedAttachments = [];
      let idx = -1;
      switch (attachmentType) {
        case AppConstants.AttachmentType.DependentBirthDoc.Type:
          attachments = [..._birthDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setBirthDoc(attachments);
          }

          break;
        case AppConstants.AttachmentType.DependentCPFProofDoc.Type:
          attachments = [..._cpfProofDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setCPFProofDoc(attachments);
          }

          break;
        case AppConstants.AttachmentType.DependentIdentityDoc.Type:
          attachments = [..._identityDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setIdentityDoc(attachments);
          }
          break;
        default:
          break;
      }
      if (file instanceof File) {
      } else {
        deletedAttachments = [..._deletedDocs];
        deletedAttachments.push(file);
        _setDeletedDocs(deletedAttachments);
      }
      _setIsFileUpdated(true);
      return Promise.resolve(null);
    };
  const onFileDownload = (file: IFileInfo): Promise<void> => {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .downloadUserDoc((props as any).user.UserId, file.fileId, file.fileName)
      .then((res) => {
        let r = res;
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        console.log(err);
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.FileDownloadError"));
      });
    return Promise.resolve(null);
  };
  const onFilesAdded =
    (attachmentType: string) =>
    (files: File[]): Promise<void> => {
      let attachments = [];
      switch (attachmentType) {
        case AppConstants.AttachmentType.DependentBirthDoc.Type:
          attachments = [..._birthDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setBirthDoc(attachments);
          break;
        case AppConstants.AttachmentType.DependentCPFProofDoc.Type:
          attachments = [..._cpfProofDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setCPFProofDoc(attachments);
          break;
        case AppConstants.AttachmentType.DependentIdentityDoc.Type:
          attachments = [..._identityDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setIdentityDoc(attachments);
          break;
        default:
          break;
      }
      _setIsFileUpdated(true);
      return Promise.resolve();
    };
  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: DependentUserFormTXTEnum) {
    let userDependentInfo = JSON.parse(JSON.stringify(_userDependentInfo)) as IUserDependentInfo;
    switch (fieldType) {
      case DependentUserFormTXTEnum.CPFNumber:
        userDependentInfo.CPF.Number = text;
        break;
      case DependentUserFormTXTEnum.EmissionState:
        userDependentInfo.RG.EState = text;
        break;
      case DependentUserFormTXTEnum.FullName:
        userDependentInfo.FullName = text;
        break;
      case DependentUserFormTXTEnum.IDNumber:
        userDependentInfo.RG.Number = text;
        break;
      case DependentUserFormTXTEnum.IssuimgAuthority:
        userDependentInfo.RG.IAuthority = text;
        break;
      case DependentUserFormTXTEnum.MotherName:
        userDependentInfo.MName = text;
        break;
      default:
        break;
    }
    _setUserDependentInfo(userDependentInfo);
  }
  function _onDLLChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption, ddlOptionType: DependentUserFormDLLEnum) {
    let userDependentInfo = JSON.parse(JSON.stringify(_userDependentInfo)) as IUserDependentInfo;
    switch (ddlOptionType) {
      case DependentUserFormDLLEnum.Relationship:
        userDependentInfo.Relationship = item.key as string;
        break;
      case DependentUserFormDLLEnum.Gender:
        userDependentInfo.Gender = item.key as string;
        break;
      case DependentUserFormDLLEnum.MaritalStatus:
        userDependentInfo.mStatus = item.key as string;
        break;
      default:
        break;
    }
    _setUserDependentInfo(userDependentInfo);
  }

  function checkandSaveForm(data: IUserDependentInfo) {
    let isSaveCallRequired = false;
    /// validate data of form
    let isDataValidated = Utility.IsUserDependentInfoValidated(data);
    if (isDataValidated) {
      if (props.userDependentData) {
        /// compare if there is any change in data
        if (JSON.stringify(props.userDependentData) != JSON.stringify(data)) {
          isSaveCallRequired = true;
        } else {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.NoValueChangeFound"));
        }
      } else {
        /// form is new and update require
        isSaveCallRequired = true;
      }
    } else {
      /// Show validation error message
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
    }
    if (isSaveCallRequired) {
      props.saveAndShowDependentForm(data, props.userDependentData ? false : true);
    } else {
      appContext.ShowAppLoader(false, "");
    }
  }
  function saveDependentUserData() {
    appContext.ShowAppLoader(true, "");
    let userID = (props as any).user.UserId;
    /// validate page data
    if (!Utility.IsUserDependentInfoValidated(_userDependentInfo, ValidationType.FieldOnly)) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
      appContext.ShowAppLoader(false, "");
      return;
    }
    if (!Utility.IsRequiredFormDocsPresent([_birthDoc, _cpfProofDoc])) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
      appContext.ShowAppLoader(false, "");
      return;
    }
    if (!Utility.IsFilesSizeValid([_birthDoc, _cpfProofDoc, _identityDoc])) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FileSizeLimit"));
      appContext.ShowAppLoader(false, "");
      return;
    }
    dataProvider
      .deleteUserDocs(userID, _deletedDocs)
      .then((delResponse: any) => {
        if (delResponse?.hasError) {
          console.log(delResponse);
          if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
            appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentDeleteError"));
          }
          appContext.ShowAppLoader(false, "");
        } else {
          Promise.all([
            dataProvider.uploadUserDocs(userID, AppConstants.AttachmentType.DependentBirthDoc.Display, _birthDoc, _userDependentInfo.DID),
            dataProvider.uploadUserDocs(userID, AppConstants.AttachmentType.DependentCPFProofDoc.Display, _cpfProofDoc, _userDependentInfo.DID),
            dataProvider.uploadUserDocs(userID, AppConstants.AttachmentType.DependentIdentityDoc.Display, _identityDoc, _userDependentInfo.DID),
          ])
            .then((res) => {
              let errorMSG = false;
              if (!res[0]?.hasError) {
                _userDependentInfo.BCertificate = res[0].data as IAppFileInfo[];
                if (_userDependentInfo.BCertificate.length > 0) {
                  /// only single file require in json
                  _userDependentInfo.BCertificate = [_userDependentInfo.BCertificate[0]];
                }
              } else {
                console.log(res[0]?.ErrorDetail);
                errorMSG = true;
              }
              if (!res[1]?.hasError) {
                _userDependentInfo.CPF.Doc = res[1].data as IAppFileInfo[];
                if (_userDependentInfo.CPF.Doc.length > 0) {
                  /// only single file require in json
                  _userDependentInfo.CPF.Doc = [_userDependentInfo.CPF.Doc[0]];
                }
              } else {
                console.log(res[1]?.ErrorDetail);
                errorMSG = true;
              }
              if (!res[2]?.hasError) {
                _userDependentInfo.RG.Doc = res[2].data as IAppFileInfo[];
                if (_userDependentInfo.RG.Doc.length > 0) {
                  /// only single file require in json
                  _userDependentInfo.RG.Doc = [_userDependentInfo.RG.Doc[0]];
                }
              } else {
                console.log(res[2]?.ErrorDetail);
                errorMSG = true;
              }
              if (errorMSG) {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentUploadError"));
                appContext.ShowAppLoader(false, "");
              } else {
                _setUserDependentInfo(_userDependentInfo);
                checkandSaveForm(_userDependentInfo);
              }
            })
            .catch((err) => {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
              console.log(err);
              appContext.ShowAppLoader(false, "");
            });
        }
      })
      .catch((err) => {
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
        console.log(err);
        appContext.ShowAppLoader(false, "");
      });
  }
  return (
    <Stack className="formSection formAdditional" horizontal>
      <StackItem className="formSubSection">
        <Stack>
          <StackItem className="fields">
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.FullName")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <TextField
                  disabled={!props.isFormEditable}
                  autoComplete="off"
                  value={_userDependentInfo.FullName}
                  onChange={(ev, text) => {
                    _onChangeText(ev, text, DependentUserFormTXTEnum.FullName);
                  }}
                ></TextField>
              </StackItem>
            </Stack>
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.Relationship")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <Dropdown
                  disabled={!props.isFormEditable}
                  calloutProps={{ directionalHintFixed: true }}
                  options={_relationShipOptions}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                    _onDLLChange(event, item, DependentUserFormDLLEnum.Relationship);
                  }}
                  selectedKey={_userDependentInfo.Relationship}
                />
              </StackItem>
            </Stack>
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.Gender")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <Dropdown
                  disabled={!props.isFormEditable}
                  calloutProps={{ directionalHintFixed: true }}
                  options={_genderOptions}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                    _onDLLChange(event, item, DependentUserFormDLLEnum.Gender);
                  }}
                  selectedKey={_userDependentInfo.Gender}
                />
              </StackItem>
            </Stack>
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.BirthDate")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <AppCalendar
                  IsEditable={props.isFormEditable}
                  InitialDate={_userDependentInfo.BDate ? new Date(_userDependentInfo.BDate) : null}
                  GetCalendatDate={function (date: Date): void {
                    _userDependentInfo.BDate = date.toUTCString();
                    _setUserDependentInfo(_userDependentInfo);
                  }}
                />
              </StackItem>
            </Stack>

            <Stack className="field">
              <StackItem className="label">
                <Checkbox
                  label={t("UserDependentDetail.FormFields.AddDependentFormFields.IncomeTaxDependent")}
                  checked={_userDependentInfo.IsDependentForIncome == "TRUE" ? true : false}
                  onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                    let data = JSON.parse(JSON.stringify(_userDependentInfo)) as IUserDependentInfo;
                    data.IsDependentForIncome = checked ? "TRUE" : "FALSE";
                    _setUserDependentInfo(data);
                  }}
                />
              </StackItem>
            </Stack>

            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.MotherName")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <TextField
                  disabled={!props.isFormEditable}
                  autoComplete="off"
                  value={_userDependentInfo.MName}
                  onChange={(ev, text) => {
                    _onChangeText(ev, text, DependentUserFormTXTEnum.MotherName);
                  }}
                ></TextField>
              </StackItem>
            </Stack>
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.MaritalStatus")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value">
                <Dropdown
                  disabled={!props.isFormEditable}
                  calloutProps={{ directionalHintFixed: true }}
                  options={_maritalStatusOptions}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                    _onDLLChange(event, item, DependentUserFormDLLEnum.MaritalStatus);
                  }}
                  selectedKey={_userDependentInfo.mStatus}
                />
              </StackItem>
            </Stack>
            <Stack className="field">
              <StackItem className="label">
                {t("UserDependentDetail.FormFields.AddDependentFormFields.BirthCertificate")}
                <span className="imp">*</span>
              </StackItem>
              <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                <AttachmentComponent
                  texts={Utility.getAttachmentTexts()}
                  fileMode="SingleFile"
                  attachment={_birthDoc.length > 0 ? _birthDoc[0] : null}
                  enableDropZone={true}
                  extensionFilters={AppConstants.fileExtensionAllowed}
                  onFileDeleted={onFileDeleted(AppConstants.AttachmentType.DependentBirthDoc.Type)}
                  onFileDownload={onFileDownload}
                  onFilesAdded={onFilesAdded(AppConstants.AttachmentType.DependentBirthDoc.Type)}
                  readonly={!props.isFormEditable}
                ></AttachmentComponent>
                <FileSizeMSG files={_birthDoc} />
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem className="formSubSection">
        <Stack>
          <StackItem className="fields">
            <fieldset className="fieldsetsection">
              <legend className="legentStyle">{t("UserDependentDetail.FormFields.AddDependentFormFields.CPF")}</legend>
              <Stack>
                <Stack className="field">
                  <StackItem className="label">
                    {t("UserDependentDetail.FormFields.AddDependentFormFields.CPFSection.ProofofCPF")}
                    <span className="imp">*</span>
                  </StackItem>
                  <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                    <AttachmentComponent
                      texts={Utility.getAttachmentTexts()}
                      fileMode="SingleFile"
                      attachment={_cpfProofDoc.length > 0 ? _cpfProofDoc[0] : null}
                      enableDropZone={true}
                      extensionFilters={AppConstants.fileExtensionAllowed}
                      onFileDeleted={onFileDeleted(AppConstants.AttachmentType.DependentCPFProofDoc.Type)}
                      onFileDownload={onFileDownload}
                      onFilesAdded={onFilesAdded(AppConstants.AttachmentType.DependentCPFProofDoc.Type)}
                      readonly={!props.isFormEditable}
                    ></AttachmentComponent>
                    <FileSizeMSG files={_cpfProofDoc} />
                  </StackItem>
                </Stack>
                <Stack className="field">
                  <StackItem className="label">
                    {t("UserDependentDetail.FormFields.AddDependentFormFields.CPFSection.CPFNumber")}
                    <span className="imp">*</span>
                  </StackItem>
                  <StackItem className="value">
                    <TextField
                      disabled={!props.isFormEditable}
                      autoComplete="off"
                      value={_userDependentInfo.CPF.Number}
                      onChange={(ev, text) => {
                        _onChangeText(ev, text, DependentUserFormTXTEnum.CPFNumber);
                      }}
                    ></TextField>
                  </StackItem>
                </Stack>
              </Stack>
            </fieldset>
            <fieldset className="fieldsetsection">
              <legend className="legentStyle">{t("UserDependentDetail.FormFields.AddDependentFormFields.RG")}</legend>
              <Stack>
                <Stack className="field">
                  <StackItem className="label">{t("UserDependentDetail.FormFields.AddDependentFormFields.RGSection.IdentityDocument")}</StackItem>
                  <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                    <AttachmentComponent
                      texts={Utility.getAttachmentTexts()}
                      fileMode="SingleFile"
                      attachment={_identityDoc.length > 0 ? _identityDoc[0] : null}
                      enableDropZone={true}
                      extensionFilters={AppConstants.fileExtensionAllowed}
                      onFileDeleted={onFileDeleted(AppConstants.AttachmentType.DependentIdentityDoc.Type)}
                      onFileDownload={onFileDownload}
                      onFilesAdded={onFilesAdded(AppConstants.AttachmentType.DependentIdentityDoc.Type)}
                      readonly={!props.isFormEditable}
                    ></AttachmentComponent>
                    <FileSizeMSG files={_identityDoc} />
                  </StackItem>
                </Stack>
                <Stack className="field">
                  <StackItem className="label">{t("UserDependentDetail.FormFields.AddDependentFormFields.RGSection.IDNumber")}</StackItem>
                  <StackItem className="value">
                    <TextField
                      disabled={!props.isFormEditable}
                      autoComplete="off"
                      value={_userDependentInfo.RG.Number}
                      onChange={(ev, text) => {
                        _onChangeText(ev, text, DependentUserFormTXTEnum.IDNumber);
                      }}
                    ></TextField>
                  </StackItem>
                </Stack>
                <Stack className="field">
                  <StackItem className="label">{t("UserDependentDetail.FormFields.AddDependentFormFields.RGSection.IssuingAuthority")}</StackItem>
                  <StackItem className="value">
                    <TextField
                      disabled={!props.isFormEditable}
                      autoComplete="off"
                      value={_userDependentInfo.RG.IAuthority}
                      onChange={(ev, text) => {
                        _onChangeText(ev, text, DependentUserFormTXTEnum.IssuimgAuthority);
                      }}
                    ></TextField>
                  </StackItem>
                </Stack>
                <Stack className="field">
                  <StackItem className="label">{t("UserDependentDetail.FormFields.AddDependentFormFields.RGSection.EmissionDate")}</StackItem>
                  <StackItem className="value">
                    <AppCalendar
                      IsEditable={props.isFormEditable}
                      InitialDate={_userDependentInfo.RG.EDate ? new Date(_userDependentInfo.RG.EDate) : null}
                      GetCalendatDate={function (date: Date): void {
                        _userDependentInfo.RG.EDate = date.toUTCString();
                        _setUserDependentInfo(_userDependentInfo);
                      }}
                    />
                  </StackItem>
                </Stack>
                <Stack className="field">
                  <StackItem className="label">{t("UserDependentDetail.FormFields.AddDependentFormFields.RGSection.EmissionState")}</StackItem>
                  <StackItem className="value">
                    <TextField
                      disabled={!props.isFormEditable}
                      autoComplete="off"
                      value={_userDependentInfo.RG.EState}
                      onChange={(ev, text) => {
                        _onChangeText(ev, text, DependentUserFormTXTEnum.EmissionState);
                      }}
                    ></TextField>
                  </StackItem>
                </Stack>
              </Stack>
            </fieldset>
            <Stack className="dependentActions">
              <StackItem>
                <DefaultButton
                  className="cancelBTN"
                  text={t("UserDependentDetail.FormFields.AddDependentFormFields.ActionButton.CancelDependent")}
                  onClick={() => {
                    props.showDependentForm();
                  }}
                  allowDisabledFocus
                />
                {props.isFormEditable && (
                  <DefaultButton
                    className="addBTN"
                    text={props.actionBTNText}
                    onClick={() => {
                      saveDependentUserData();
                    }}
                    allowDisabledFocus
                  />
                )}
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};
