import {
  Calendar,
  DatePicker,
  DefaultButton,
  defaultCalendarStrings,
  defaultDatePickerStrings,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  IDatePickerStrings,
  INavLink,
  INavLinkGroup,
  Nav,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { RedButton } from "ccs-azure";
import { AppConstants } from "components/Utility/Constants";
import { Utility } from "components/Utility/Utility";
import { ICalendarDataState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AppCalendar.scss";
import { AppTopContext } from "./AppTopContext";
import moment from "moment";
export interface IDefaultFormProps {
  GetCalendatDate: (date: Date) => void;
  InitialDate: Date;
  IsEditable: boolean;
}

export const AppCalendar: React.FunctionComponent<IDefaultFormProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  const appContext = useContext(AppTopContext);

  //   const [calendarData, setCalendarData] = useState<ICalendarDataState>({
  //     Date: props.InitialDate,
  //     IsVisible: false,
  //   });
  //   useEffect(() => {
  //     setCalendarData({
  //       ...calendarData,
  //       Date: props.InitialDate,
  //     });
  //   }, [props.InitialDate]);

  const defStrings: IDatePickerStrings = {
    ...defaultDatePickerStrings,
  };

  const BRDayPickerStrings: IDatePickerStrings = {
    ...defaultDatePickerStrings,
    months: t("Calendar.months", { returnObjects: true }),
    shortMonths: t("Calendar.shortMonths", { returnObjects: true }),
    days: t("Calendar.days", { returnObjects: true }),
    shortDays: t("Calendar.shortDays", { returnObjects: true }),
    goToToday: t("Calendar.goToToday"),
    prevMonthAriaLabel: t("Calendar.prevMonthAriaLabel"),
    nextMonthAriaLabel: t("Calendar.nextMonthAriaLabel"),
    prevYearAriaLabel: t("Calendar.prevYearAriaLabel"),
    nextYearAriaLabel: t("Calendar.nextYearAriaLabel"),
  };
  const onFormatDate = (date?: Date): string => {
    //return Utility.GetClientDateFormat(date);
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
  };
  return (
    <>
      <DatePicker
        formatDate={onFormatDate}
        disabled={!props.IsEditable}
        // DatePicker uses English strings by default. For localized apps, you must override this prop.
        strings={appContext.IsLBrazil ? BRDayPickerStrings : defStrings}
        placeholder={t("SelectDatePlaceholder")}
        allowTextInput={true}
        textField={{ disabled: true }}
        value={props.InitialDate || null}
        onSelectDate={(date: Date) => {
          props.GetCalendatDate(date);
        }}
      />
      {/* <div className='appCalendar'>
                <TextField
                    className='appCalTextBox'
                    autoComplete="off"
                    disabled
                    value={calendarData.Date?.toDateString() || 'Select Date'}
                ></TextField>
                {props.IsEditable &&
                    <Icon iconName={calendarData.IsVisible ? "CalendarReply" : "Calendar"}
                        title={t('SelectDateTooltip')}
                        className='actionIcon calIcon'
                        onClick={() => {
                            setCalendarData(
                                {
                                    ...calendarData,
                                    IsVisible: !calendarData.IsVisible
                                }
                            );
                        }}
                    />
                }
                {calendarData.IsVisible &&
                    <Calendar
                        className='toolCal'
                        showGoToToday
                        onSelectDate={(date: Date, dateRangeArray: Date[]) => {
                            setCalendarData(
                                {
                                    ...calendarData,
                                    Date: date,
                                    IsVisible: false
                                }

                            );
                            props.GetCalendatDate(date);
                        }}
                        value={calendarData.Date ? calendarData.Date : new Date()}
                        // Calendar uses English strings by default. For localized apps, you must override this prop.
                        strings={defaultCalendarStrings}
                    />
                }

            </div> */}
    </>
  );
};
