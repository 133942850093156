import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, IIconProps, INavLink, INavLinkGroup, Nav, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import { AccountType } from 'components/LoginComponent/LoginComponent';
import { SaveTypeEnum } from 'components/Utility/AppEnums';
import { AppConstants } from 'components/Utility/Constants';
import { AppTopContext } from 'components/Welcome/Common/AppTopContext';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
export interface IActionSectionProps {
    previousCallFun: any;
    nextCallFun: any;
    submitCallFun: any;
    isFormEditable: boolean;
    resetForm: any;
}


export const ActionSection: React.FunctionComponent<IActionSectionProps> = ({ ...props }) => {
    const { t, i18n } = useTranslation(['home'], { keyPrefix: 'ActionBtn' });
    const appContext = useContext(AppTopContext);
    let [_isAdminUser, _setAsAdminUser] = useState<boolean>(false);
    let [_isInEditMode, _setEditMode] = useState<boolean>(props.isFormEditable);
    useEffect(() => {
        let currentProps = props as any;
        if (currentProps.currentUser.accountType == AccountType.Microsoft) {
            _setAsAdminUser(true);
            _setEditMode(false);
        }
    }, []);
    useEffect(() => {
        _setEditMode(props.isFormEditable);
    }, [props.isFormEditable]);

    return (
        <div className='topActionSection'>
            <div className='actionSection'>
                <Stack horizontal={true}>
                    {_isAdminUser &&
                        (props as any)?.user?.Status == AppConstants.UserFilterOptions.Submitted &&
                        <StackItem className='adminActionOnly'>
                            <DefaultButton
                                iconProps={_isInEditMode ? { iconName: 'Uneditable' } : { iconName: 'Edit' }}
                                text={_isInEditMode ? t('ExitEditMode') : t('ChangeToEdit')}
                                onClick={() => {
                                    if (_isInEditMode && props.resetForm != null) {
                                        props.resetForm();
                                    }
                                    else {
                                        let mode = !_isInEditMode;
                                        _setEditMode(mode);
                                        (props as any).updateFormMode(mode);
                                    }
                                }}
                                allowDisabledFocus />
                        </StackItem>
                    }
                    <Stack horizontal={true} className="bottomActions">
                        {props.previousCallFun &&
                            <StackItem className='previousCall'>
                                <DefaultButton
                                    iconProps={{ iconName: 'Back' }}
                                    text={_isInEditMode ? t('SaveAndBack') : t('Back')}
                                    onClick={
                                        () => {
                                            if (_isInEditMode) {
                                                props.previousCallFun(SaveTypeEnum.BackSave);
                                            }
                                            else {
                                                props.previousCallFun(SaveTypeEnum.Back);
                                            }
                                        }
                                    }
                                    allowDisabledFocus />
                            </StackItem>
                        }
                        {props.nextCallFun &&
                            <StackItem className='saveCall'>
                                <DefaultButton iconProps={{ iconName: 'Forward' }}
                                    text={_isInEditMode ? t('SaveAndNext') : t('Next')}
                                    onClick={
                                        () => {
                                            if (_isInEditMode) {
                                                props.nextCallFun(SaveTypeEnum.NextSave);
                                            }
                                            else {
                                                props.nextCallFun(SaveTypeEnum.Next);
                                            }
                                        }
                                    }
                                    allowDisabledFocus />
                            </StackItem>
                        }
                        {props.submitCallFun && _isInEditMode && !_isAdminUser &&
                            < StackItem className='submitCall'>
                                <DefaultButton text={t('Submit')}
                                    iconProps={{ iconName: 'SaveAndClose' }}
                                    onClick={
                                        () => {
                                            props.submitCallFun(SaveTypeEnum.Submit)
                                        }
                                    } allowDisabledFocus />
                            </StackItem>
                        }
                    </Stack>
                </Stack>

            </div>
        </div >

    );
};
ActionSection.defaultProps = {
    previousCallFun: undefined,
    nextCallFun: undefined,
    submitCallFun: undefined
}
