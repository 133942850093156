import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon, DialogType, SpinnerSize, ThemeSettingName } from '@fluentui/react';
// import { PageURL } from '../../App';
import './Welcome.scss';
import { AccountType, IUserAccount, LoginComponent } from '../LoginComponent/LoginComponent';
// import { Redirect } from 'react-router';
import DataProvider from '../../dataprovider/DataProvider';
import { FormLoader } from './Common/Loader';
import { ModalComponent } from './Common/ModalComponent';
import { AppConstants } from '../Utility/Constants';
import { UserProfileForm } from './HRForm/UserProfileForm/UserProfileForm';
import { AdminHome } from './HRForm/AdminPanel/AdminDashBoard/AdminHome';
import { Confirmation } from './Common/Confirmation';
import { AppTopContext } from './Common/AppTopContext';
import { IAppChoicesState } from 'Interfaces/IHRBrazil';
import { Utility } from 'components/Utility/Utility';
import i18next, { t } from 'i18next';
const theme = getTheme();
export interface IWelcomePageProps {
    location: Location;
    history: any;
    stepSizeClass: string;
    isLoggedIn: boolean;
    dataProvider: DataProvider;
    currentUser?: IUserAccount;
    sessionExpired: any;
    isLBrazil: boolean;
}

export interface IWelcomePageState {
    showLoader: boolean;
    loaderMSG: string;
    isLoggedIn: boolean;
    modalValues: any;
    confirmationValues: any;
    AppChoices: IAppChoicesState;

}

export class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {
    constructor(props: IWelcomePageProps) {
        super(props);
        this.state = {
            AppChoices: {
                Country: [],
                Gender: [],
                MaritalStatus: [],
                Nationality: [],
                RaceColor: [],
                Relation: [],
                UserStatus: [],
                UserStatusFilters: [],
                EducationLevel: [],
                Bank: [],
                AccountType: [],
                ChoicesFound: false
            },
            modalValues: { hideDialog: true, header: "", msg: "" },
            confirmationValues: {
                confirmationMessage: "",
                confirmationHeader: "",
                confirmationYesBTN: "",
                confirmationNoBTN: "",
                showConfirmation: false
            },
            showLoader: false,
            loaderMSG: "",
            isLoggedIn: props.isLoggedIn

        };
        this.ShowAppLoader = this.ShowAppLoader.bind(this);
        this.ShowAppModal = this.ShowAppModal.bind(this);
        this.ShowAppModalWithFun = this.ShowAppModalWithFun.bind(this);
        this.ShowAppConfirmationModal = this.ShowAppConfirmationModal.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }
    public componentDidMount() {
        var dataProvider = new DataProvider();
        dataProvider.getAppChoices()
            .then((appChoices) => {
                if (!appChoices.hasError) {
                    let choiceData: IAppChoicesState = {
                        AccountType: Utility.GetChoiceData(AppConstants.choices.AccountType, appChoices.data), 
                        Bank: Utility.GetChoiceData(AppConstants.choices.Bank, appChoices.data),
                        Gender: Utility.GetChoiceData(AppConstants.choices.Gender, appChoices.data),
                        RaceColor: Utility.GetChoiceData(AppConstants.choices.RaceColor, appChoices.data),
                        Relation: Utility.GetChoiceData(AppConstants.choices.Relation, appChoices.data),
                        MaritalStatus: Utility.GetChoiceData(AppConstants.choices.MaritalStatus, appChoices.data),
                        Nationality: Utility.GetChoiceData(AppConstants.choices.Nationality, appChoices.data),
                        Country: Utility.GetChoiceData(AppConstants.choices.Country, appChoices.data),
                        EducationLevel: Utility.GetChoiceData(AppConstants.choices.EducationLevel, appChoices.data),
                        UserStatus: [AppConstants.UserStatusForFilterData[1], AppConstants.UserStatusForFilterData[2]],
                        UserStatusFilters: AppConstants.UserStatusForFilterData,
                        ChoicesFound: true
                    };
                    (this.props.currentUser as any).AppChoices = choiceData;
                    this.setState({
                        AppChoices: choiceData
                    }, () => {
                        (this.props.currentUser as any).AppChoices = choiceData;
                        // console.log(choiceData);
                    });
                }
                else {
                    console.log("Error in getting choice fields.");
                    console.log(appChoices.errorDetail);
                    if (!Utility.IsUnauthorizeError(appChoices.errorDetail, null)) {
                        this.ShowAppModal(i18next.t('home:ModalDialogHeader.Error'), i18next.t('home:Error.ApiCallError.ErrorInGetAppChoicesData'));
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.ShowAppModal(i18next.t('home:ModalDialogHeader.Error'), i18next.t('home:Error.ApiCallError.ErrorInGetAppChoices'));
            });
    }

    public componentWillReceiveProps(newProps: IWelcomePageProps) {
        if (this.state.isLoggedIn != newProps.isLoggedIn || this.props.isLBrazil != newProps.isLBrazil) {
            this.setState({ isLoggedIn: newProps.isLoggedIn }, () => {
                // this.setLoggedInInformation();
            });
        }
    }

    public ShowAppLoader(showLoader: boolean, msg: any) {
        this.setState({
            loaderMSG: msg.length > 0 ? msg : i18next.t('home:loaderMSG'),
            showLoader: showLoader
        });
    }
    public ShowAppModal(header: string, message: string) {
        this.setState({
            modalValues: { hideDialog: false, header: header, msg: message }
        });
    }
    public ShowAppModalWithFun(header: string, message: string, func: any) {
        this.setState({
            modalValues: { hideDialog: false, header: header, msg: message, func: func }
        }, () => {
            // func();
        });
    }
    public ShowAppConfirmationModal(header: string, message: string, okBTN: string, cancelBTN: string, func: any) {
        this.setState({
            confirmationValues: {
                confirmationMessage: message,
                confirmationHeader: header,
                confirmationYesBTN: okBTN,
                confirmationNoBTN: cancelBTN,
                showConfirmation: true,
                func: func
            }
        });
    }
    public closeDialog() {
        var func = this.state.modalValues.func;
        this.setState({
            modalValues: { hideDialog: true, header: "", msg: "" }
        }, () => {
            if (func) {
                func();
            }
        });
    }
    public confirmedDialog() {
        var func = this.state.confirmationValues.func;
        this.setState({
            confirmationValues: {
                confirmationMessage: "",
                confirmationHeader: "",
                confirmationYesBTN: "",
                confirmationNoBTN: "",
                showConfirmation: false
            }
        }, () => {
            if (func) {
                func();
            }
        });
    }
    protected HideConfirmation() {
        this.setState({
            confirmationValues: {
                confirmationMessage: "",
                confirmationHeader: "",
                confirmationYesBTN: "",
                confirmationNoBTN: "",
                showConfirmation: false
            }
        });
    }
    Profile = React.memo(() => {
        return (
            <Stack className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
                {/* {"Test Time" + (new Date()).toLocaleTimeString()} */}

                <Stack verticalAlign="space-between" horizontalAlign="start" className={css("welcomeContainer", this.props.stepSizeClass, "animate", "popIn")} tokens={{ childrenGap: 5 }}>
                    {this.state.isLoggedIn
                        ? <>
                            {this.props.currentUser &&
                                <>
                                    {this.props.currentUser.accountType == AccountType.Microsoft
                                        && <>
                                            <AdminHome {...this.props}
                                            />
                                        </>
                                    }
                                    {this.props.currentUser.accountType == AccountType.Custom
                                        && <>
                                            <UserProfileForm {...this.props}
                                                itemID={this.props.currentUser.id}
                                            />
                                        </>
                                    }
                                </>
                            }

                        </>
                        :
                        ""
                    }
                </Stack>
            </Stack>
        );
    });
    public render(): React.ReactElement<IWelcomePageProps> {
        return (
            <AppTopContext.Provider value={{
                ShowAppLoader: this.ShowAppLoader,
                ShowAppModal: this.ShowAppModal,
                ShowAppModalWithFun: this.ShowAppModalWithFun,
                ShowAppConfirmationModal: this.ShowAppConfirmationModal,
                ExpireSession: this.props.sessionExpired,
                IsLBrazil: this.props.isLBrazil,
                IsUserAdmin: this.props.currentUser?.accountType == AccountType.Microsoft ? true : false
            }}>
                {!this.state.modalValues.hideDialog &&
                    <ModalComponent
                        header={this.state.modalValues.header}
                        message={this.state.modalValues.msg}
                        closeDialog={this.closeDialog}
                    />
                }
                {this.state.showLoader &&
                    <FormLoader
                        DialogType={DialogType.normal}
                        ShowLoader={this.state.showLoader}
                        SpinnerLabel={this.state.loaderMSG}
                        SpinnerSize={SpinnerSize.large}
                    />
                }
                {this.state.confirmationValues.showConfirmation &&
                    <Confirmation header={this.state.confirmationValues.confirmationHeader}
                        buttonYesText={this.state.confirmationValues.confirmationYesBTN}
                        buttonCancelText={this.state.confirmationValues.confirmationNoBTN}
                        message={this.state.confirmationValues.confirmationMessage}
                        confirmed={this.confirmedDialog.bind(this)}
                        hideConfirmedDialog={this.HideConfirmation.bind(this)}
                    />
                }
                {this.state.AppChoices.ChoicesFound == true
                    &&
                    <this.Profile />
                }
            </AppTopContext.Provider>
        );
    }
}

