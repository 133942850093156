import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  INavLink,
  INavLinkGroup,
  Nav,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { AttachmentComponent, IFileInfo } from "ccs-azure";
import { Document1FormTXTEnum, SaveTypeEnum, ValidationType } from "components/Utility/AppEnums";
import { Utility } from "components/Utility/Utility";
import { AppCalendar } from "components/Welcome/Common/AppCalendar";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import { FileSizeMSG } from "components/Welcome/Common/FileSizeMSG";
import DataProvider from "dataprovider/DataProvider";
import { IAppFileInfo, INavigationActions, IReturnAction, IUserAdditionalData, IUserDoc1Data, IUserFieldsState, IUserProfileData } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConstants } from "../../../../Utility/Constants";
import { ActionSection } from "./ActionSection";
import "./Document1.scss";
export interface IDocuments1FormProps {
  user: IUserFieldsState;
  isFormEditable: boolean;
  previousCall: any;
  nextCall: any;
  submitCall: any;
  userProfileFormData: IUserDoc1Data;
  navigationAction: INavigationActions;
  saveUserProfile: (sectionKey: string, data: any, updateData: boolean, callFunc: any) => void;
  updateFormMode: (formMode: boolean) => void;
}

export const Documents1Form: React.FunctionComponent<IDocuments1FormProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  const appContext = useContext(AppTopContext);
  var dataProvider = new DataProvider();

  let [_emissionCountryOptions, _setEmissionCountryOptions] = useState<IDropdownOption[]>([]);
  const [_userProfileFormData, _setUserProfileFormData] = useState<IUserDoc1Data>(Utility.GetInitialData(AppConstants.SectionKey.Documents1));
  let [_isFileUpdated, _setIsFileUpdated] = useState<boolean>(false);
  let [_cpfProof, _setCPFProof] = useState<Array<IFileInfo | File>>([]);
  let [_nisDoc, _setNISDoc] = useState<Array<IFileInfo | File>>([]);
  let [_ctpsDoc, _setCTPSDoc] = useState<Array<IFileInfo | File>>([]);
  let [_passportDoc, _setPassportDoc] = useState<Array<IFileInfo | File>>([]);
  let [_identityDoc, _setIdentityDoc] = useState<Array<IFileInfo | File>>([]);
  let [_fnr, _setFNR] = useState<Array<IFileInfo | File>>([]);
  let [_deletedDocs, _setDeletedDocs] = useState<Array<IFileInfo>>([]);

  useEffect(() => {}, []);
  useEffect(() => {
    var countryOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Country);
    _setEmissionCountryOptions(countryOptions);
  }, [appContext.IsLBrazil]);
  useEffect(() => {
    if (props.navigationAction.Guid) {
      if (props.isFormEditable) {
        let isFormUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents1, props.user, _userProfileFormData);
        if (appContext.IsUserAdmin) {
          if (isFormUpdated) {
            appContext.ShowAppModal(t("Information.AdminPanelAction.Header"), t("Information.AdminPanelAction.MSG"));
          } else {
            props.navigationAction.NavigationFun();
          }
        } else {
          callPropSaveDataCallBackFunction(isFormUpdated, props.navigationAction.NavigationFun);
        }
      } else {
        props.navigationAction.NavigationFun();
      }
    }
  }, [props.navigationAction.Guid]);
  useEffect(() => {
    initializeForm();
  }, [props.userProfileFormData]);

  function initializeForm() {
    let initialData = Utility.GetInitialData(AppConstants.SectionKey.Documents1, props.userProfileFormData) as IUserDoc1Data;
    _setCPFProof(Utility.GetFiles(initialData.CPF.Doc));
    _setNISDoc(Utility.GetFiles(initialData.NIS.Doc));
    _setCTPSDoc(Utility.GetFiles(initialData.CTPS.Doc));

    _setPassportDoc(Utility.GetFiles(initialData.Passport.Passport));
    _setIdentityDoc(Utility.GetFiles(initialData.RG.Doc));
    _setFNR(Utility.GetFiles(initialData?.NotBrazilian.FNRegistration));
    _setUserProfileFormData(initialData);
    _setDeletedDocs([]);
  }
  function resetForm() {
    /// check for change and reset
    let isFormDataUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents1, props.user, _userProfileFormData);
    if (isFormDataUpdated) {
      appContext.ShowAppConfirmationModal(
        t("ActionBtn.ExitEditModeConfirmation.Header"),
        t("ActionBtn.ExitEditModeConfirmation.MSG"),
        t("ActionBtn.ExitEditModeConfirmation.OKBtnTxt"),
        t("ActionBtn.ExitEditModeConfirmation.CancelBtnTxt"),
        function () {
          /// call reset data
          initializeForm();
        }
      );
    } else {
      props.updateFormMode(false);
    }
  }
  function callPropSaveDataCallBackFunction(isDataUpdateRequire: boolean, func: any) {
    appContext.ShowAppLoader(true, "");
    if (isDataUpdateRequire) {
      if (appContext.IsUserAdmin) {
        /// validate page data
        if (!Utility.IsDoc1FormValidated(_userProfileFormData, ValidationType.FieldOnly)) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
        if (!Utility.IsRequiredFormDocsPresent([_cpfProof, _nisDoc, _ctpsDoc, _identityDoc])) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
      }

      if (!Utility.IsFilesSizeValid([_nisDoc, _ctpsDoc, _passportDoc, _cpfProof, _fnr, _identityDoc])) {
        appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FileSizeLimit"));
        appContext.ShowAppLoader(false, "");
        return;
      }
      dataProvider
        .deleteUserDocs(props.user.UserId, _deletedDocs)
        .then((delResponse: any) => {
          if (delResponse?.hasError) {
            console.log(delResponse);
            if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentDeleteError"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            Promise.all([
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1CPFProof.Display, _cpfProof),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1NISDoc.Display, _nisDoc),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1CTPSDoc.Display, _ctpsDoc),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1PassportDoc.Display, _passportDoc),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1FNR.Display, _fnr),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc1IdentityDoc.Display, _identityDoc),
            ])
              .then((res) => {
                let errorMSG = false;
                if (!res[0]?.hasError) {
                  _userProfileFormData.CPF.Doc = res[0].data as IAppFileInfo[];
                  if (_userProfileFormData.CPF.Doc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.CPF.Doc = [_userProfileFormData.CPF.Doc[0]];
                  }
                } else {
                  console.log(res[0]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[1]?.hasError) {
                  _userProfileFormData.NIS.Doc = res[1].data as IAppFileInfo[];
                  if (_userProfileFormData.NIS.Doc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.NIS.Doc = [_userProfileFormData.NIS.Doc[0]];
                  }
                } else {
                  console.log(res[1]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[2]?.hasError) {
                  _userProfileFormData.CTPS.Doc = res[2].data as IAppFileInfo[];
                  if (_userProfileFormData.CTPS.Doc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.CTPS.Doc = [_userProfileFormData.CTPS.Doc[0]];
                  }
                } else {
                  console.log(res[2]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[3]?.hasError) {
                  _userProfileFormData.Passport.Passport = res[3].data as IAppFileInfo[];
                  if (_userProfileFormData.Passport.Passport.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.Passport.Passport = [_userProfileFormData.Passport.Passport[0]];
                  }
                } else {
                  console.log(res[3]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[4]?.hasError) {
                  _userProfileFormData.NotBrazilian.FNRegistration = res[4].data as IAppFileInfo[];
                  if (_userProfileFormData.NotBrazilian.FNRegistration.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.NotBrazilian.FNRegistration = [_userProfileFormData.NotBrazilian.FNRegistration[0]];
                  }
                } else {
                  console.log(res[4]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[5]?.hasError) {
                  _userProfileFormData.RG.Doc = res[5].data as IAppFileInfo[];
                  if (_userProfileFormData.RG.Doc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.RG.Doc = [_userProfileFormData.RG.Doc[0]];
                  }
                } else {
                  console.log(res[5]?.ErrorDetail);
                  errorMSG = true;
                }
                if (errorMSG) {
                  appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentUploadError"));
                  appContext.ShowAppLoader(false, "");
                } else {
                  props.saveUserProfile(AppConstants.SectionKey.Documents1, _userProfileFormData, isDataUpdateRequire, func);
                }
              })
              .catch((err) => {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
                console.log(err);
                appContext.ShowAppLoader(false, "");
              });
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
          console.log(err);
          appContext.ShowAppLoader(false, "");
        });
    } else {
      func();
    }
  }
  function actionCallbackFun(saveType: SaveTypeEnum) {
    let actionProps: IReturnAction = Utility.GetActionProps(saveType, props.previousCall, props.nextCall);
    if (actionProps.saveRequired) {
      actionProps.saveRequired = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents1, props.user, _userProfileFormData);
    }
    callPropSaveDataCallBackFunction(actionProps.saveRequired, actionProps.callBack);
  }
  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: Document1FormTXTEnum) {
    let documentFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc1Data;
    switch (fieldType) {
      case Document1FormTXTEnum.CPFNo:
        documentFormData.CPF.Number = text;
        break;
      case Document1FormTXTEnum.CTPSDigit:
        documentFormData.CTPS.Digit = text;
        break;
      case Document1FormTXTEnum.CTPSNo:
        documentFormData.CTPS.Number = text;
        break;
      case Document1FormTXTEnum.CTPSSerie:
        documentFormData.CTPS.Serie = text;
        break;
      case Document1FormTXTEnum.CTPSState:
        documentFormData.CTPS.State = text;
        break;
      case Document1FormTXTEnum.NISNo:
        documentFormData.NIS.Number = text;
        break;
      case Document1FormTXTEnum.NoFRN:
        documentFormData.NotBrazilian.FRN = text;
        break;
      case Document1FormTXTEnum.NoFVC:
        documentFormData.NotBrazilian.VisaCondition = text;
        break;
      case Document1FormTXTEnum.NoIAuthority:
        documentFormData.NotBrazilian.IssuingAuthority = text;
        break;
      case Document1FormTXTEnum.PEState:
        documentFormData.Passport.EmissionState = text;
        break;
      case Document1FormTXTEnum.PIAuthority:
        documentFormData.Passport.IssuingAuthority = text;
        break;
      case Document1FormTXTEnum.PassportNo:
        documentFormData.Passport.Number = text;
        break;
      case Document1FormTXTEnum.IDNumber:
        documentFormData.RG.Number = text;
        break;
      case Document1FormTXTEnum.IssuimgAuthority:
        documentFormData.RG.IAuthority = text;
        break;
      case Document1FormTXTEnum.EmissionState:
        documentFormData.RG.EState = text;
        break;

      default:
        break;
    }
    _setUserProfileFormData(documentFormData);
  }
  const onFileDeleted =
    (attachmentType: string) =>
    (file: IFileInfo | File): Promise<void> => {
      let attachments = [];
      let deletedAttachments = [];
      let idx = -1;
      switch (attachmentType) {
        case AppConstants.AttachmentType.Doc1CPFProof.Type:
          attachments = [..._cpfProof];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setCPFProof(attachments);
          }

          break;
        case AppConstants.AttachmentType.Doc1CTPSDoc.Type:
          attachments = [..._ctpsDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setCTPSDoc(attachments);
          }

          break;
        case AppConstants.AttachmentType.Doc1FNR.Type:
          attachments = [..._fnr];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setFNR(attachments);
          }

          break;
        case AppConstants.AttachmentType.Doc1NISDoc.Type:
          attachments = [..._nisDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setNISDoc(attachments);
          }
          break;
        case AppConstants.AttachmentType.Doc1PassportDoc.Type:
          attachments = [..._passportDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setPassportDoc(attachments);
          }
          break;
        case AppConstants.AttachmentType.Doc1IdentityDoc.Type:
          attachments = [..._identityDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setIdentityDoc(attachments);
          }
          break;
        default:
          break;
      }
      if (file instanceof File) {
      } else {
        deletedAttachments = [..._deletedDocs];
        deletedAttachments.push(file);
        _setDeletedDocs(deletedAttachments);
      }
      _setIsFileUpdated(true);
      return Promise.resolve(null);
    };
  const onFileDownload = (file: IFileInfo): Promise<void> => {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .downloadUserDoc(props.user.UserId, file.fileId, file.fileName)
      .then((res) => {
        let r = res;
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        console.log(err);
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.FileDownloadError"));
      });
    return Promise.resolve(null);
  };
  const onFilesAdded =
    (attachmentType: string) =>
    (files: File[]): Promise<void> => {
      let attachments = [];
      switch (attachmentType) {
        case AppConstants.AttachmentType.Doc1CPFProof.Type:
          attachments = [..._cpfProof];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setCPFProof(attachments);
          break;
        case AppConstants.AttachmentType.Doc1CTPSDoc.Type:
          attachments = [..._ctpsDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setCTPSDoc(attachments);
          break;
        case AppConstants.AttachmentType.Doc1FNR.Type:
          attachments = [..._fnr];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setFNR(attachments);
          break;
        case AppConstants.AttachmentType.Doc1NISDoc.Type:
          attachments = [..._nisDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setNISDoc(attachments);
          break;
        case AppConstants.AttachmentType.Doc1PassportDoc.Type:
          attachments = [..._passportDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setPassportDoc(attachments);
          break;
        case AppConstants.AttachmentType.Doc1IdentityDoc.Type:
          attachments = [..._identityDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setIdentityDoc(attachments);
          break;
        default:
          break;
      }
      _setIsFileUpdated(true);
      return Promise.resolve();
    };
  return (
    <>
      <Stack className="formHeader">{t("UserDocuments1.FormHeader")}</Stack>
      <ActionSection
        {...props}
        resetForm={resetForm}
        isFormEditable={props.isFormEditable}
        previousCallFun={actionCallbackFun}
        nextCallFun={actionCallbackFun}
        submitCallFun={undefined}
      />
      <Stack className="formSection formDocument1" horizontal>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.CPF")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CPFSection.CPFProof")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_cpfProof.length > 0 ? _cpfProof[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1CPFProof.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1CPFProof.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_cpfProof} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CPFSection.CPFNumber")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CPF.Number}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.CPFNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>

              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.IdentityNumber")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.IdentityNumberSection.NISDoc")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_nisDoc.length > 0 ? _nisDoc[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1NISDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1NISDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_nisDoc} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.IdentityNumberSection.NISNo")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.NIS.Number}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.NISNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IdentityNumberSection.EmissionDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.NIS.EmissionDate ? new Date(_userProfileFormData.NIS.EmissionDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.NIS.EmissionDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.CTPS")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CTPSSection.CTPSDoc")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_ctpsDoc.length > 0 ? _ctpsDoc[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1CTPSDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1CTPSDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_ctpsDoc} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CTPSSection.CTPSNo")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        autoComplete="off"
                        disabled={!props.isFormEditable}
                        value={_userProfileFormData.CTPS.Number}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.CTPSNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CTPSSection.Serie")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CTPS.Serie}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.CTPSSerie);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.CTPSSection.Digit")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CTPS.Digit}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.CTPSDigit);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.CTPSSection.State")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CTPS.State}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.CTPSState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.CTPSSection.DispatchDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.CTPS.DispatchDate ? new Date(_userProfileFormData.CTPS.DispatchDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.CTPS.DispatchDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.RG")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.RGSection.IdentityDocument")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_identityDoc.length > 0 ? _identityDoc[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1IdentityDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1IdentityDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_identityDoc} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.RGSection.IDNumber")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.RG.Number}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.IDNumber);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.RGSection.IssuingAuthority")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.RG.IAuthority}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.IssuimgAuthority);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.RGSection.EmissionDate")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.RG.EDate ? new Date(_userProfileFormData.RG.EDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.RG.EDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments1.FormFields.RGSection.EmissionState")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.RG.EState}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.EmissionState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.PassportSectionName")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.PassportDoc")}</StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_passportDoc.length > 0 ? _passportDoc[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1PassportDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1PassportDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_passportDoc} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.PassportNo")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.Passport.Number}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.PassportNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.IssuingAuthority")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.Passport.IssuingAuthority}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.PIAuthority);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.EmissionDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.Passport.EmissionDate ? new Date(_userProfileFormData.Passport.EmissionDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.Passport.EmissionDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.ExpirationDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.Passport.ExpirationDate ? new Date(_userProfileFormData.Passport.ExpirationDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.Passport.ExpirationDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.EmissionCountry")}</StackItem>
                    <StackItem className="value">
                      <Dropdown
                        disabled={!props.isFormEditable}
                        calloutProps={{ directionalHintFixed: true }}
                        options={_emissionCountryOptions}
                        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                          let userProfileFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc1Data;
                          userProfileFormData.Passport.EmissionCountry = item.key as string;
                          _setUserProfileFormData(userProfileFormData);
                        }}
                        selectedKey={_userProfileFormData.Passport.EmissionCountry}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.PassportSection.EmissionState")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.Passport.EmissionState}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.PEState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>

              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments1.FormFields.IfNotBrazilianSectionName")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.ForeignerNationalRegistration")}</StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_fnr.length > 0 ? _fnr[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc1FNR.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc1FNR.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_fnr} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.DateofArrivalinBrazil")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.NotBrazilian.ArrivalDate ? new Date(_userProfileFormData.NotBrazilian.ArrivalDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.NotBrazilian.ArrivalDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.ForeignVisaCondition")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.NotBrazilian.VisaCondition}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.NoFVC);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.ForeignRegistrationNumber")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.NotBrazilian.FRN}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.NoFRN);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.IssuingAuthority")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.NotBrazilian.IssuingAuthority}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document1FormTXTEnum.NoIAuthority);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.DispatchDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.NotBrazilian.DispatchDate ? new Date(_userProfileFormData.NotBrazilian.DispatchDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.NotBrazilian.DispatchDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.VisaValidity")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.NotBrazilian.VisaValidity ? new Date(_userProfileFormData.NotBrazilian.VisaValidity) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.NotBrazilian.VisaValidity = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.EmploymentRecordValidity")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={
                          _userProfileFormData.NotBrazilian.EmploymentValidity ? new Date(_userProfileFormData.NotBrazilian.EmploymentValidity) : null
                        }
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.NotBrazilian.EmploymentValidity = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments1.FormFields.IfNotBrazilianSection.DoyouhaveBrazilianChildren")}</StackItem>
                    <StackItem className="value">
                      <ChoiceGroup
                        disabled={!props.isFormEditable}
                        selectedKey={_userProfileFormData.NotBrazilian.HaveBrazilianChildren}
                        options={[
                          { key: "Y", text: t("UserDocuments1.FormFields.IfNotBrazilianSection.YesHaveChildren") },
                          { key: "N", text: t("UserDocuments1.FormFields.IfNotBrazilianSection.NoHaveChildren") },
                        ]}
                        onChange={(ev: React.SyntheticEvent<HTMLElement>, option: IChoiceGroupOption) => {
                          let data = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc1Data;
                          data.NotBrazilian.HaveBrazilianChildren = option.key;
                          _setUserProfileFormData(data);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </>
  );
};
