import {
  ActionButton,
  DatePicker,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  Icon,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  IIconProps,
  INavLink,
  INavLinkGroup,
  Nav,
  Panel,
  PanelType,
  SelectionMode,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { AppConstants } from "components/Utility/Constants";
import { AdminPanelFormEnum } from "components/Utility/AppEnums";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import DataProvider from "dataprovider/DataProvider";
import { IAdminPanelState, IAppChoicesState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { UserProfileForm } from "../../UserProfileForm/UserProfileForm";
import "./AdminHome.scss";
import { UserInformationForm } from "./UserInformationForm";
import { useTranslation } from "react-i18next";
import { Utility } from "components/Utility/Utility";
import moment from "moment";
export interface IAdminHomeProps {}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 200 },
};
const addProfileSearchIcon: IIconProps = {
  iconName: "ProfileSearch",
};

const addFriendIcon: IIconProps = { iconName: "AddFriend" };
export const AdminHome: React.FunctionComponent<IAdminHomeProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);

  let [_panelOptions, _setPanelOptions] = useState<IAdminPanelState>({
    PanelHeader: "",
    PanelControlType: AdminPanelFormEnum.NewUserForm,
    IsPanelOpen: false,
    SelectedUser: null,
  });
  let [_statusOptions, _SetstatusOptions] = useState<IDropdownOption[]>([]);

  let [_filteredText, _setFilteredText] = useState<string>("");
  const [_filteredDate, _setFilteredDate] = useState<Date | undefined>(undefined);
  let [_userParamIDChecked, _setUserParamIDChecked] = useState<boolean>(false);
  let [_filteredStatus, _setFilteredStatus] = useState<string>("");
  let [_userProfileData, _setUserProfileData] = useState<any>([]);
  const refItems = useRef(_userProfileData);

  const detailListColumns: IColumn[] = [
    {
      key: "email",
      name: t("AdminDashboard.DataListColumns.Email"),
      // fieldName: 'email',
      minWidth: 100,
      isResizable: true,
      isRowHeader: true,
      isCollapsible: true,
      data: "string",
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        return item.email;
      },
    },
    {
      key: "firstname",
      name: t("AdminDashboard.DataListColumns.FirstName"),
      // fieldName: 'firstName',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: _onColumnClick,
      data: "string",
      isPadded: true,
      onRender: (item: any) => {
        return item.firstName;
      },
    },
    {
      key: "lastname",
      name: t("AdminDashboard.DataListColumns.LastName"),
      // fieldName: 'lastName',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: _onColumnClick,
      data: "string",
      isPadded: true,
      onRender: (item: any) => {
        return item.lastName;
      },
    },
    {
      key: "hiringdate",
      name: t("AdminDashboard.DataListColumns.HiringDate"),
      // fieldName: 'lastName',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: _onColumnClick,
      data: "string",
      isPadded: true,
      onRender: (item: any) => {
        if (item.hiringDate) {
          return Utility.GetClientDateFormat(new Date(item.hiringDate));
          //return moment(item.hiringDate).format("DD/MM/YYYY");
        }
        return "";
      },
    },
    {
      key: "password",
      name: t("AdminDashboard.DataListColumns.Password"),
      // fieldName: 'password',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      onColumnClick: _onColumnClick,
      isPadded: true,
      onRender: (item: any) => {
        return item.password;
      },
    },
    {
      key: "status",
      name: t("AdminDashboard.DataListColumns.Status"),
      // fieldName: 'status',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      onColumnClick: _onColumnClick,
      isPadded: true,
      onRender: (item: any) => {
        let retVal = "";
        retVal = item.status == AppConstants.UserFilterOptions.Draft ? t("AdminDashboard.UserStatus.Draft") : t("AdminDashboard.UserStatus.Submitted");
        return retVal;
      },
    },
    {
      key: "validated",
      name: t("AdminDashboard.DataListColumns.Validated"),
      // fieldName: 'status',
      minWidth: 100,
      // maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      onColumnClick: _onColumnClick,
      isPadded: true,
      onRender: (item: any) => {
        let retVal = "";
        retVal = item.validated ? "X" : "";
        return retVal;
      },
    },
    {
      key: "action",
      name: t("AdminDashboard.DataListColumns.Action"),
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: any) => {
        return (
          <>
            <Icon
              iconName="EditContact"
              title={t("AdminDashboard.DataListColumns.ActionIcons.EditUserInfoIconTitle")}
              className="actionIcon"
              onClick={() => SetPanelProps(t("AdminDashboard.DataListColumns.ActionIcons.EditUserHeader"), item, AdminPanelFormEnum.EditUserForm)}
            />
            <Icon
              iconName="PageEdit"
              title={t("AdminDashboard.DataListColumns.ActionIcons.EditUserProfileIconTitle")}
              className="actionIcon"
              onClick={() => SetPanelProps(t("AdminDashboard.DataListColumns.ActionIcons.EditUserProfileHeader"), item, AdminPanelFormEnum.UserProfile)}
            />
            <Icon
              iconName="Delete"
              title={t("AdminDashboard.DataListColumns.ActionIcons.DeleteUserIconTitle")}
              className="actionIcon"
              onClick={() => {
                appContext.ShowAppConfirmationModal(
                  t("AdminDashboard.DataListColumns.ActionIcons.DeleteAction.DeleteFormConfirmationHeader"),
                  t("AdminDashboard.DataListColumns.ActionIcons.DeleteAction.DeleteFormConfirmationText"),
                  t("AdminDashboard.DataListColumns.ActionIcons.DeleteAction.DeleteFormConfirmationOKBtn"),
                  t("AdminDashboard.DataListColumns.ActionIcons.DeleteAction.DeleteFormConfirmationHCancelBtn"),
                  function () {
                    deleteItem(item);
                  }
                );
              }}
            />
          </>
        );
      },
    },
  ];
  let [_gridColumns, _setGridColumns] = useState<IColumn[]>(detailListColumns);

  /// get context type
  const appContext = useContext(AppTopContext);
  var dataProvider = new DataProvider();

  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    var options = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.UserStatusFilters);
    // alert("data")
    if (options.length > 0) {
      _SetstatusOptions(options);
      if (!_filteredStatus) {
        if (options.length > 0) {
          _setFilteredStatus(options[0].key as string);
        }
      }
    }
  }, [(props as any)?.currentUser?.AppChoices, appContext.IsLBrazil]);

  useEffect(() => {
    _gridColumns.forEach((c) => {
      if (c.key != "action") {
        c.onColumnClick = _onColumnClick;
      }
      c.name = GetColumnName(c.key);
    });

    _setGridColumns(_gridColumns);
    let userData = JSON.parse(JSON.stringify(_userProfileData));
    _setUserProfileData(userData);
  }, [JSON.stringify(_userProfileData), appContext.IsLBrazil]);

  function GetColumnName(key: string) {
    let colName = "";
    switch (key) {
      case "email":
        colName = t("AdminDashboard.DataListColumns.Email");
        break;
      case "firstname":
        colName = t("AdminDashboard.DataListColumns.FirstName");
        break;
      case "lastname":
        colName = t("AdminDashboard.DataListColumns.LastName");
        break;
      case "password":
        colName = t("AdminDashboard.DataListColumns.Password");
        break;
      case "status":
        colName = t("AdminDashboard.DataListColumns.Status");
        break;
      case "action":
        colName = t("AdminDashboard.DataListColumns.Action");
        break;
      case "validated":
        colName = t("AdminDashboard.DataListColumns.Validated");
        break;
      case "hiringdate":
        colName = t("AdminDashboard.DataListColumns.HiringDate");
        break;
      default:
        break;
    }
    return colName;
  }
  function RenderPanelComponent() {
    let componentView;
    switch (_panelOptions.PanelControlType) {
      case AdminPanelFormEnum.NewUserForm:
        componentView = <UserInformationForm {...props} formType={AdminPanelFormEnum.NewUserForm} itemID={0} cancelPanel={cancelPanel} />;
        break;
      case AdminPanelFormEnum.EditUserForm:
        componentView = (
          <UserInformationForm {...props} formType={AdminPanelFormEnum.EditUserForm} itemID={_panelOptions.SelectedUser.profileId} cancelPanel={cancelPanel} />
        );
        break;
      case AdminPanelFormEnum.UserProfile:
        componentView = <UserProfileForm {...props} itemID={_panelOptions.SelectedUser.profileId} cancelPanel={cancelPanel} />;
        break;

      default:
        componentView = <></>;
        break;
    }
    return componentView;
  }

  function SetPanelProps(header: string, item: any, userFormType: AdminPanelFormEnum) {
    _setPanelOptions({
      PanelHeader: header,
      PanelControlType: userFormType,
      IsPanelOpen: true,
      SelectedUser: item,
    });
  }
  function deleteItem(item: any) {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .deleteUser(item.profileId)
      .then((result) => {
        if (result.hasError) {
          console.log(result.errorDetail);
          if (!Utility.IsUnauthorizeError(result.errorDetail, appContext)) {
            appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInDeletingUserApiResult"));
          }
        } else {
          appContext.ShowAppModalWithFun(t("ModalDialogHeader.Success"), t("Success.UserDeletionMSG"), getUserData);
        }
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInDeletingUserApiCall"));
      });
  }
  function getUserData() {
    const userQuery = {
      status: _filteredStatus == AppConstants.UserFilterOptions.All ? "" : _filteredStatus,
      text: _filteredText,
      date: _filteredDate ? new Date(Date.UTC(_filteredDate.getFullYear(), _filteredDate.getMonth(), _filteredDate.getDate())).toISOString() : null,
    };
    appContext.ShowAppLoader(true, "");
    dataProvider
      .getAllUserProfiles(userQuery)
      .then((userProfiles) => {
        let items = [];

        if (userProfiles.hasError) {
          if (!Utility.IsUnauthorizeError(userProfiles.errorDetail, appContext)) {
            items = [];
          }
        } else {
          items = userProfiles.data;
        }
        refItems.current = items;
        _setUserProfileData(items);

        /// check single time for query string parameter for default user ID to open
        if (!_userParamIDChecked) {
          let userParamID = Utility.getDefaultUser();
          if (userParamID) {
            let filteredUser = items.filter((item: any) => item.profileId == userParamID);
            if (filteredUser.length == 1) {
              SetPanelProps(t("AdminDashboard.DataListColumns.ActionIcons.EditUserProfileHeader"), filteredUser[0], AdminPanelFormEnum.UserProfile);
            }
          }
          _setUserParamIDChecked(true);
        }

        appContext.ShowAppLoader(false, "");
      })
      .catch((error) => {
        console.log(error);
        refItems.current = [];
        _setUserProfileData([]);
        appContext.ShowAppLoader(false, "");
      });
  }
  function _getKey(item: any, index?: number): string {
    return item.key;
  }
  function _onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn) {
    const newColumns: IColumn[] = _gridColumns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(refItems.current, currColumn.fieldName!, currColumn.isSortedDescending);
    refItems.current = newItems;
    _setUserProfileData(newItems as []);
    _setGridColumns(newColumns);
  }
  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string) {
    _setFilteredDate(null);
    _setFilteredText(text);
  }
  function _onStatusChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
    _setFilteredStatus(item.key as string);
  }

  function cancelPanel() {
    _setPanelOptions({
      PanelHeader: "",
      PanelControlType: AdminPanelFormEnum.None,
      IsPanelOpen: false,
      SelectedUser: null,
    });
    getUserData();
  }

  const handleDateChange = (date: Date | null | undefined): void => {
    _setFilteredText("");
    _setFilteredDate(date);
  };

  return (
    <>
      <Panel
        id="userProfilepanel"
        isOpen={_panelOptions.IsPanelOpen}
        headerClassName="panelHeader"
        className="adminPanelContent"
        type={PanelType.large}
        isBlocking={true}
        closeButtonAriaLabel="Close"
        headerText={_panelOptions.PanelHeader}
        onDismiss={(event: any) => {
          let processPanel = false;
          if (event) {
            if (event.type.toLowerCase() == "click") {
              processPanel = true;
            }
          } else {
            processPanel = true;
          }
          if (processPanel) {
            cancelPanel();
          }
        }}
      >
        {_panelOptions.IsPanelOpen && RenderPanelComponent()}
      </Panel>
      <fieldset className="manageUserProfiles">
        <Stack>
          <Stack horizontal className="searchSection">
            <Stack.Item className="searchText">
              <TextField
                label={t("AdminDashboard.FilterOptions.TextSearch")}
                autoComplete="off"
                placeholder={t("AdminDashboard.FilterOptions.TextSearchPlaceholder")}
                className="userSearch"
                width={200}
                value={_filteredText}
                onChange={_onChangeText}
              ></TextField>
            </Stack.Item>
            <Stack.Item className="searchDate">
              <Stack horizontal>
                <DatePicker
                  value={_filteredDate ?? null}
                  onSelectDate={handleDateChange}
                  label={t("AdminDashboard.FilterOptions.DateSearch")}
                  formatDate={(date?: Date) => Utility.GetClientDateFormat(date)}
                  styles={{ root: { display: "inline-block" } }}
                ></DatePicker>
                <IconButton
                  iconProps={{ iconName: "Cancel" }}
                  styles={{ root: { display: "inline-block", color: "black", alignSelf: "center", marginTop: 25 } }}
                  onClick={() => handleDateChange(null)}
                />
              </Stack>
            </Stack.Item>
            <Stack.Item className="searchOptions">
              <Dropdown
                calloutProps={{ directionalHintFixed: true }}
                label={t("AdminDashboard.FilterOptions.UserStatus")}
                onChange={_onStatusChange}
                placeholder={t("AdminDashboard.FilterOptions.UserStatusPlaceholder")}
                options={_statusOptions}
                styles={dropdownStyles}
                selectedKey={_filteredStatus}
              />
            </Stack.Item>
            <StackItem className="searchIcon">
              <Icon iconName="ProfileSearch" title={t("AdminDashboard.FilterOptions.SearchIconTooltip")} className="actionIcon" onClick={() => getUserData()} />
            </StackItem>
          </Stack>
          <Stack>
            <StackItem>
              <ActionButton
                iconProps={addFriendIcon}
                allowDisabledFocus
                onClick={() => {
                  SetPanelProps(t("AdminDashboard.NewUserPanelHeader"), null, AdminPanelFormEnum.NewUserForm);
                }}
              >
                {t("AdminDashboard.InviteNewUser")}
              </ActionButton>
            </StackItem>
          </Stack>
          {_userProfileData.length > 0 ? (
            <Stack className="userProfilesGrid">
              <DetailsList
                items={_userProfileData}
                compact={false}
                columns={_gridColumns}
                selectionMode={SelectionMode.none}
                getKey={_getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                // onRenderItemColumn={_renderItemColumn}
                // onItemInvoked={this._onItemInvoked}
              />
            </Stack>
          ) : (
            <Stack>
              <div className="noDataAvailable"> {t("AdminDashboard.NoDataAvailable")}</div>
            </Stack>
          )}
        </Stack>
      </fieldset>
    </>
  );
};
