import { IUserDependentInfo } from "Interfaces/IHRBrazil";
import i18next, { t } from "i18next";
var AppConstants = {
  fileExtensionAllowed: [".pdf", ".jpeg", ".jpg", ".png"],
  maritalStatus: {
    Single: "Single",
    Married: "Married",
  },
  capchaKey: "6LdzrhwoAAAAAAmPXTD-TaQjYIwHmlv-B0UvnIp0",
  i18NameSpaces: ["home"],
  Storage: {
    authToken: "authToken",
    appSession: "HRBrazilApp",
  },
  languageStorage: "HRBrazilAppLng",
  validationStatusLabel: "validationStatusLabel",
  languageSupport: {
    ptBR: "pt-BR",
    en: "en",
  },
  languageSupportInit: {
    ptBR: "PTBR",
    en: "EN",
  },
  choices: {
    Gender: "Gender",
    RaceColor: "RaceColor",
    Relation: "Relation",
    MaritalStatus: "MaritalStatus",
    EducationLevel: "EducationLevel",
    Nationality: "Nationality",
    Country: "Country",
    UserStatus: "UserStatus",
    UserStatusFilters: "UserStatusFilters",
    Bank: "Bank",
    AccountType: "AccountType",
  },
  SectionKey: {
    BasicData: "BasicData",
    AdditionalData: "AdditionalData",
    Documents1: "Documents1",
    Documents2: "Documents2",
    DependentDetail: "DependentDetail",
    AllFormData: "AllFormData",
    UserDependentInfo: "UserDependentInfo",
  },

  AttachmentType: {
    BadgePhotoDocs: {
      Type: "BadgePhotoDocs",
      Display: "Badge Photo",
    },
    CovidCertificateDocs: {
      Type: "CovidCertificateDocs",
      Display: "Covid Vaccine",
    },
    EducationDocs: {
      Type: "EducationDocs",
      Display: "Education Certificate",
    },
    OtherCertificates: {
      Type: "OtherCertificates",
      Display: "Certificates",
    },
    VoterDocument: {
      Type: "VoterDocument",
      Display: "Voter Document",
    },
    MaritalDocs: {
      Type: "MaritalDocs",
      Display: "Marital Certificate",
    },
    AddressDocs: {
      Type: "AddressDocs",
      Display: "Address Proof",
    },
    Doc1CPFProof: {
      Type: "Doc1CPFProof",
      Display: "Natural Person Registry",
    },
    Doc1NISDoc: {
      Type: "Doc1NISDoc",
      Display: "Employee NIS Doc",
    },
    Doc1CTPSDoc: {
      Type: "DocCTPSDoc",
      Display: "Employee Record Booklet Doc",
    },
    Doc1PassportDoc: {
      Type: "Doc1PassportDoc",
      Display: "Passport",
    },
    Doc1IdentityDoc: {
      Type: "Doc1IdentityDoc",
      Display: "Identity Proof",
    },
    Doc1FNR: {
      Type: "Doc1FNR",
      Display: "Foreigner National Registration",
    },
    Doc2LicenseDoc: {
      Type: "Doc2LicenseDoc",
      Display: "License Document",
    },
    Doc2MilitaryCertificate: {
      Type: "Doc2MilitaryCertificate",
      Display: "Military Certificate",
    },
    Doc2BankDoc: {
      Type: "Doc2BankDoc",
      Display: "Bank Document",
    },
    DependentBirthDoc: {
      Type: "DependentBirthDoc",
      Display: "Dependent Birth Proof",
    },
    DependentCPFProofDoc: {
      Type: "DependentCPFProofDoc",
      Display: "Dependent CPF Proof",
    },
    DependentIdentityDoc: {
      Type: "DependentIdentityDoc",
      Display: "Dependent Identity Proof",
    },
  },
  UserFilterOptions: {
    All: "All",
    Draft: "Draft",
    Submitted: "Submitted",
  },

  UserStatusForFilterData: [
    {
      en: "All",
      br: "Todo",
    },
    {
      en: "Draft",
      br: "Rascunho",
    },
    {
      en: "Submitted",
      br: "Enviada",
    },
  ],
  AccountType: {
    Custom: "Custom",
    Microsoft: "Microsoft",
  },
};

export { AppConstants };
