import { IDataProvider } from "./IDataProvider";
import HttpService from "./HttpService";
import { IAuthenticatedUser, IUserAccount } from "../components/LoginComponent/LoginComponent";
import { IUserFieldsState } from "Interfaces/IHRBrazil";
import { IFileInfo } from "ccs-azure";
import { AppConstants } from "components/Utility/Constants";

export default class DataProvider implements IDataProvider {
  public apiEndpointSP = `/api/SPInterface`;
  public userAccountEndpointSP = `/api/UserAccountSPInterface`;

  public async uploadUserDocs(profileId: string, docType: string, files: Array<IFileInfo | File>, DID: string = ""): Promise<any> {
    files = files.map((file, index) => {
      if (file instanceof File) return file;
    });
    var formData = new FormData();
    if (DID) {
      formData.append(`did`, DID);
    }
    formData.append(`id`, profileId);
    formData.append(`docType`, docType);
    files.map((file, index) => {
      if (file instanceof File) formData.append(`file${index}`, file);
    });
    let authToken = window.sessionStorage[AppConstants.Storage.authToken] ? JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]) : null;
    let response = await fetch(`${this.apiEndpointSP}/UploadUserDocs`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authToken ? `Bearer ${authToken.token}` : "",
      },
    });

    if (response.ok) return response.json();
    else if (response.status == 401) {
      throw "Upload CV Error: Unauthorized. Please sign out and sign back in and re-try.";
    } else throw await response.json();
  }

  public async downloadUserDoc(userId: string, docID: string, docName: string): Promise<any> {
    const response = await HttpService.getFileDownload(
      `${this.apiEndpointSP}/DownloadDocument/${encodeURIComponent(userId)}/${encodeURIComponent(docID)}/${encodeURIComponent(docName)}`,
      docName
    );
    return response;
  }
  public async deleteUserDocs(userId: string, deletedFiles: Array<IFileInfo>): Promise<any> {
    if (deletedFiles.length > 0) {
      const response = await HttpService.post(`${this.apiEndpointSP}/DeleteUserDocs`, {
        UserId: userId,
        DocIds: deletedFiles.map((x) => x.fileId),
      });
      return response;
    } else {
      return Promise.resolve(null);
    }
  }
  public async getAppChoices(): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/GetAppChoices/`);
    return response;
  }
  public async getAppValues(): Promise<any> {
    const response = await HttpService.get(`${this.apiEndpointSP}/GetAppValues/`, "", "", false);
    return response;
  }

  public async getUserProfileByID(userID: number, findDocs: boolean): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/GetUserProfile`, {
      IsDocRequired: findDocs,
      UserId: userID.toString(),
    });
    return response;
  }
  public async sendUserMailToAdmin(isLBrazil: boolean, userID: number, mailBody: string): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/SendUserMailToAdmin`, {
      MailBody: mailBody,
      UserId: userID.toString(),
      Locale: isLBrazil ? AppConstants.languageSupportInit.ptBR : AppConstants.languageSupportInit.en,
    });
    return response;
  }
  public async updateUserProfile(isLBrazil: boolean, user: IUserFieldsState, userDocs: string[] = []): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/UpdateUserProfile`, {
      User: user,
      UserDocs: userDocs,
      Locale: isLBrazil ? AppConstants.languageSupportInit.ptBR : AppConstants.languageSupportInit.en,
    });
    return response;
  }
  public async getAllUserProfiles(userQuery: any): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/GetAllUserProfiles`, userQuery);
    return response;
  }

  public async deleteUser(userID: number): Promise<any> {
    const response = await HttpService.post(`${this.apiEndpointSP}/DeleteUserProfile`, userID);
    return response;
  }

  public ensureMicrosoftLogin(user: IUserAccount, token: string, isUserToken: boolean): Promise<IAuthenticatedUser> {
    if (user.password) {
    } else {
      user.password = "";
    }
    if (user.userId) {
    } else {
      user.userId = "";
    }
    return HttpService.post(`${this.userAccountEndpointSP}/EnsureMicrosoftLogin`, { Account: user, Token: token, IsUserToken: isUserToken }, "", false);
  }

  public loginExternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<IAuthenticatedUser> {
    return HttpService.post(`${this.userAccountEndpointSP}/CustomUserLogin`, { Account: user, reCaptchaToken: reCaptchaToken }, "", false);
  }
}
