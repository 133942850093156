import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, INavLink, INavLinkGroup, Nav, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
export interface IDefaultFormProps {
}


export const DefaultForm: React.FunctionComponent<IDefaultFormProps> = ({ ...props }) => {

    useEffect(() => {

    }, []);

    return (
        <>
            Default Section

        </>
    );
};
