import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, INavLink, INavLinkGroup, Nav, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import { IFileInfo } from 'ccs-azure';
import { AppConstants } from 'components/Utility/Constants';
import { Utility } from 'components/Utility/Utility';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
export interface IFileSizeMSGProps {
    files: (IFileInfo | File)[]
}


export const FileSizeMSG: React.FunctionComponent<IFileSizeMSGProps> = ({ ...props }) => {
    let [_filesSize, _setFilesSize] = useState<number>(0);
    const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);

    useEffect(() => {
        let filesSize = Utility.GetFilesSize(props.files);
        _setFilesSize(filesSize);
    }, [props.files]);

    return (
        <>
            {_filesSize > 0 &&
                <div>{t('FileSizeMSG')} <span>{_filesSize} {t('FileSizeMBUnit')}</span></div>
            }
        </>
    );
};
