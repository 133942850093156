import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
//import { useIsAuthenticated } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AccountType, IAuthenticatedUser, IUserAccount } from './LoginComponent';
import { Dialog, DialogType, Spinner, SpinnerSize } from "@fluentui/react";
import { AppConstants } from "components/Utility/Constants";
import { Utility } from "components/Utility/Utility";
import i18next, { t } from 'i18next';

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
    //prompt: 'select_account',
};

function parseMicrosoftUser(idToken: any): IUserAccount {
    return {
        firstName: idToken.idTokenClaims?.given_name,
        lastName: idToken.idTokenClaims?.family_name,
        email: idToken.idTokenClaims?.email,
        accountType: AccountType.Microsoft,
        userId: null,
        activateGuid: null,
        id: null,
        password: null
    } as unknown as IUserAccount;
}

function handleLogin(validUsers: any, instance: any, callback: (userInformation: IUserAccount, token: string) => Promise<IAuthenticatedUser>, setAuthenticated: (a: boolean) => void, showError: (e: string) => void, setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser | null) => Promise<void>, setRedirect: (a: boolean) => void, setShowWaitingSpinner: (a: boolean) => void) {
    return instance.loginPopup(loginRequest).then((result: any) => {
        // console.log("MICROSOFT ACCOUNT", result);
        let user = parseMicrosoftUser(result?.account);
        var userIsValid = false;
        if (validUsers.length == 0) {
            userIsValid = true;
        }
        else {
            validUsers.forEach((element: any) => {
                if (element.toLowerCase()) {
                    if (user.email?.toLowerCase().endsWith(element.toLowerCase()) == true) {
                        userIsValid = true;
                        return;
                    }
                }
            });
        }
        if (userIsValid == true) {
            setShowWaitingSpinner(true);
            callback(user, result.idToken).then((spUser: IAuthenticatedUser) => {
                if (spUser) {
                    setAuthStatus(true, spUser);
                    setAuthenticated(true);
                } else {
                    setAuthStatus(false, spUser);
                    setAuthenticated(false);
                    // for now, don't sign user in yet
                    Utility.CleanSession();
                }
                setShowWaitingSpinner(false);

            }).catch((err) => {
                setShowWaitingSpinner(false);

                console.log("EnsureUser: " + err);
                instance.logoutRedirect({
                    onRedirectNavigate: (url: string) => {
                        // Return false if you would like to stop navigation after local logout
                        return false;
                    }
                });
                setAuthStatus(false, null);
                setAuthenticated(false);

                Utility.CleanSession();
                showError("Microsoft");
            });
        }
        else {
            alert("User:- " + user.email + " is not valid for this app.");
            instance.logoutPopup().then((result: any) => {
                setAuthenticated(false);
                setAuthStatus(false, null);
            }).catch((err: string) => {
                console.error(err);
            });
        }

    }).catch((err: string) => {
        console.error(err);
    });
}

function handleLogout(instance: any, setAuthenticated: (a: boolean) => void, setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser | null) => Promise<void>) {
    instance.logoutPopup().then((result: any) => {
        setAuthenticated(false);
        setAuthStatus(false, null);
    }).catch((err: string) => {
        console.error(err);
    });
}

export interface IMicrosoftComponentProps {
    currentUser: IAuthenticatedUser | null;
    signedIn: boolean;
    callback: (userInformation: IUserAccount, token: string, isUserToken: boolean) => Promise<IAuthenticatedUser>;
    showError: (error: string) => void;
    setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser | null) => Promise<void>;
    msUsersAllowed: [];
}


export const MicrosoftComponent = (props: IMicrosoftComponentProps) => {
    const { instance, accounts } = useMsal();

    //console.log("MS Accounts", accounts);

    const [authenticated, setAuthenticated] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [showWaitingSpinner, setShowWaitingSpinner] = useState(false);
    const [userName, setUserName] = useState(props.currentUser ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : "");
    useEffect(() => {
        if (props.signedIn) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }

        if (props.currentUser) {

            let currentUser = window.sessionStorage[AppConstants.Storage.appSession] ? JSON.parse(window.sessionStorage[AppConstants.Storage.appSession]) : null;
            setUserName(
                currentUser?.displayName ? currentUser.displayName : (
                    props.currentUser ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : ""
                ));
        }

    });

    return (
        <>
            {authenticated ?
                <><div>{i18next.t('home:LoginForm.WelcomeUserPretext')}:<br /><span className='loginDisplayName'>{`${userName}`}</span><br /><Button variant="secondary" className="microsoftButton" style={{ marginTop: '10px' }} onClick={() => handleLogout(instance, setAuthenticated, props.setAuthStatus)}>{i18next.t('home:LoginForm.LogoutText')}</Button></div></>
                :
                <Button style={{
                    width: '200px',
                    height: '32px',
                    fontSize: '14px',
                    fontFamily: 'Google Sans",arial,sans-serif',
                    fontWeight: 500,
                    color: '#3c4043',
                    letterSpacing: '0.25px',
                    position: 'relative',
                    top: '0px',
                    border: '1px solid #dadce0',
                    boxShadow: 'initial',
                    backgroundSize: '18px 18px',
                    backgroundPosition: '9px 9px'
                }} variant="secondary" className="microsoftButton"
                    onClick={
                        () =>
                            handleLogin(props.msUsersAllowed, instance,
                                (user: IUserAccount, token: string) => {
                                    return props.callback(user, token, false);
                                },
                                setAuthenticated, props.showError, props.setAuthStatus, setRedirect, setShowWaitingSpinner
                            )
                    }>{i18next.t('home:LoginForm.MSLoginButtonText')}</Button>
            }
            <Dialog
                hidden={!showWaitingSpinner}
                dialogContentProps={{
                    type: DialogType.normal,
                }}
                modalProps={{ isBlocking: true, className: "busyOverlay" }}
            >
                <Spinner size={SpinnerSize.large} label={i18next.t('home:LoginForm.CheckingMicrosoftAccountMSG')} ariaLive="assertive" />
            </Dialog>
        </>
    );
}