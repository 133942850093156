import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, INavLink, INavLinkGroup, Nav, Panel, PanelType, SpinnerType, Stack, TextField } from '@fluentui/react';
import { Label, Spinner, SpinnerSize, StackItem } from '@fluentui/react';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from '../../../../Utility/Constants';
import { ActionSection } from './ActionSection';
import './UserFormCommon.scss';
import './DependentData.scss';
import { INavigationActions, IReturnAction, IUserDependentInfo, IUserDependentPanelState, IUserFieldsState } from 'Interfaces/IHRBrazil';
import { UserDependentForm } from './DependentForm/UserDependentForm';
import { SaveTypeEnum } from 'components/Utility/AppEnums';
import { AppTopContext } from 'components/Welcome/Common/AppTopContext';
import { Utility } from 'components/Utility/Utility';
import { IFileInfo } from 'ccs-azure';
import DataProvider from 'dataprovider/DataProvider';

export interface IDependentDetailFormProps {
    user: IUserFieldsState;
    isFormEditable: boolean;
    previousCall: any;
    nextCall: any;
    submitCall: any;
    userProfileFormData: IUserDependentInfo[];
    navigationAction: INavigationActions;
    saveUserProfile: (sectionKey: string, data: any, updateData: boolean, callFunc: any) => void;
    updateFormMode: (formMode: boolean) => void;
}
export const DependentDetailForm: React.FunctionComponent<IDependentDetailFormProps> = ({ ...props }) => {
    const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
    const appContext = useContext(AppTopContext);
    var dataProvider = new DataProvider();

    let [_panelOptions, _setPanelOptions] = useState<IUserDependentPanelState>({
        PanelHeader: "",
        IsPanelOpen: false,
        SaveBTNText: ""
    });
    const [_userProfileFormData, _setUserProfileFormData] = useState<IUserDependentInfo[]>(
        props.userProfileFormData ? props.userProfileFormData : []
    );
    const [_userDependentInfo, _setUserDependentInfo] = useState<IUserDependentInfo>(null);
    const [_selectedDependentIndex, _setSelectedDependentIndex] = useState(-1);
    useEffect(() => {
        _setUserProfileFormData(props.userProfileFormData ? props.userProfileFormData : []);
    }, [props.userProfileFormData]);
    useEffect(() => {
        if (props.navigationAction.Guid) {
            if (props.isFormEditable) {
                let isFormUpdated = Utility.IsFormUpdated(false, AppConstants.SectionKey.DependentDetail, props.user, _userProfileFormData);
                if (appContext.IsUserAdmin) {
                    if (isFormUpdated) {
                        appContext.ShowAppModal(
                            t('Information.AdminPanelAction.Header'),
                            t('Information.AdminPanelAction.MSG')
                        );
                    }
                    else {
                        props.navigationAction.NavigationFun();
                    }
                }
                else {
                    callPropSaveDataCallBackFunction(isFormUpdated, props.navigationAction.NavigationFun);
                }
            }
            else {
                props.navigationAction.NavigationFun();
            }
        }
    }, [props.navigationAction.Guid]);

    function callPropSaveDataCallBackFunction(isDataUpdateRequire: boolean, func: any) {
        if (isDataUpdateRequire) {
            props.saveUserProfile
                (
                    AppConstants.SectionKey.DependentDetail,
                    _userProfileFormData,
                    isDataUpdateRequire,
                    func
                );
        }
        else {
            func();
        }
    }
    function cancelPanel() {
        _setPanelOptions({
            PanelHeader: "",
            IsPanelOpen: false,
            SaveBTNText: ""
        });
    }
    function reloadDependent() {
        _setPanelOptions({
            PanelHeader: "",
            IsPanelOpen: false,
            SaveBTNText: ""
        });
    }
    function addUpdateDependent(userInfo: IUserDependentInfo, isNewDependent: boolean) {
        if (isNewDependent) {
            _userProfileFormData.push(userInfo);
        }
        else {
            _userProfileFormData[_selectedDependentIndex] = userInfo;
        }
        _setUserProfileFormData(_userProfileFormData);
        _setPanelOptions({
            PanelHeader: "",
            IsPanelOpen: false,
            SaveBTNText: ""
        });
        callPropSaveDataCallBackFunction(true, null);
    }
    function actionCallbackFun(saveType: SaveTypeEnum) {
        if (saveType == SaveTypeEnum.Submit) {
            props.submitCall();
        } else {
            let actionProps: IReturnAction = Utility.GetActionProps(saveType, props.previousCall, props.nextCall);
            if (actionProps.saveRequired) {
                actionProps.saveRequired = Utility.IsFormUpdated(false, AppConstants.SectionKey.DependentDetail, props.user, _userProfileFormData);
            }
            callPropSaveDataCallBackFunction(actionProps.saveRequired, actionProps.callBack);
        }

    }
    function deleteDependentUser(dependentUserToDelete: IUserDependentInfo, userIndex: number) {
        appContext.ShowAppConfirmationModal(t('UserDependentDetail.ActionIcons.DeleteAction.DeleteFormConfirmationHeader'),
            t('UserDependentDetail.ActionIcons.DeleteAction.DeleteFormConfirmationText'),
            t('UserDependentDetail.ActionIcons.DeleteAction.DeleteFormConfirmationOKBtn'),
            t('UserDependentDetail.ActionIcons.DeleteAction.DeleteFormConfirmationHCancelBtn'),
            function () {
                let docsNeedToBeDeleted = Utility.getDependentUserDocs(dependentUserToDelete);
                appContext.ShowAppLoader(true, "");
                dataProvider.deleteUserDocs(props.user.UserId, docsNeedToBeDeleted)
                    .then((delResponse: any) => {
                        if (delResponse?.hasError) {
                            console.log(delResponse);
                            if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
                                appContext.ShowAppModal(
                                    t('ModalDialogHeader.Error'),
                                    t('Error.DocumentError.DocumentDeleteError')
                                );
                            }
                            appContext.ShowAppLoader(false, "");
                        }
                        else {
                            _userProfileFormData.splice(userIndex, 1);
                            _setUserProfileFormData(_userProfileFormData);
                            callPropSaveDataCallBackFunction(true, null);
                        }
                    }).catch((err) => {
                        appContext.ShowAppModal(
                            t('ModalDialogHeader.Error'),
                            t('Error.DocumentError.GenericError')
                        );
                        console.log(err);
                        appContext.ShowAppLoader(false, "");
                    });
            }
        );
    }
    return (
        <>
            <Panel
                isOpen={_panelOptions.IsPanelOpen}
                headerClassName="panelHeader"
                className='adminPanelContent'
                type={PanelType.large}
                isBlocking={true}
                closeButtonAriaLabel="Close"
                headerText={_panelOptions.PanelHeader}
                onDismiss={(event: any) => {
                    let processPanel = false;
                    if (event) {
                        if (event.type.toLowerCase() == "click") {
                            processPanel = true;
                        }
                    }
                    else {
                        processPanel = true;
                    }
                    if (processPanel) {
                        cancelPanel();
                    }
                }}
            >
                {
                    _panelOptions.IsPanelOpen &&
                    <UserDependentForm
                        saveAndShowDependentForm={addUpdateDependent}
                        userDependentData={_userDependentInfo}
                        {...props}
                        showDependentForm={reloadDependent}
                        isFormEditable={props.isFormEditable}
                        actionBTNText={_panelOptions.SaveBTNText}
                    />
                }
            </Panel>
            <Stack className='formHeader'>
                {t('UserDependentDetail.FormHeader')}
            </Stack>
            <ActionSection {...props}
                resetForm={null}
                isFormEditable={props.isFormEditable}
                previousCallFun={actionCallbackFun}
                nextCallFun={undefined} submitCallFun={actionCallbackFun} />
            <Stack className='formSection formDependents' horizontal>
                <StackItem className='formFullSection'>
                    <Stack>
                        <StackItem className='fields dependentsection'>
                            <fieldset className='fieldsetsection'>
                                <legend className='legentStyle'>{t('UserDependentDetail.FormFields.ListOfDependents')}</legend>
                                {props.userProfileFormData.length == 0
                                    ?
                                    <Stack className='field'>
                                        {t('UserDependentDetail.FormFields.NoDependentsAvailable')}
                                    </Stack>
                                    :
                                    <Stack>
                                        {_userProfileFormData.map((dependentItem, i) => {
                                            return (<StackItem key={dependentItem.FullName + i}>
                                                {dependentItem.FullName + " ( " + dependentItem.Relationship + " ) "}
                                                <span>{dependentItem.Gender}</span>
                                                <div className='dependentActionIcons'>
                                                    <Icon iconName="EditContact"
                                                        title={t('UserDependentDetail.ActionIcons.EditUserInfoIconTitle')}
                                                        className='actionIcon viewIcon'
                                                        onClick={() => {
                                                            _setPanelOptions({
                                                                PanelHeader: t('UserDependentDetail.Panel.UpdatePanelHeader'),
                                                                IsPanelOpen: true,
                                                                SaveBTNText: t('UserDependentDetail.FormFields.AddDependentFormFields.ActionButton.UpdateDependent')
                                                            });
                                                            _setUserDependentInfo(dependentItem);
                                                            _setSelectedDependentIndex(i);
                                                        }
                                                        }
                                                    />
                                                    {props.isFormEditable &&

                                                        <Icon iconName="Delete"
                                                            title={t('UserDependentDetail.ActionIcons.DeleteUserIconTitle')}
                                                            className='actionIcon deleteIcon'
                                                            onClick={() => {
                                                                deleteDependentUser(dependentItem, i);
                                                            }
                                                            } />
                                                    }
                                                </div>

                                            </StackItem>);
                                        })}
                                    </Stack>
                                }
                            </fieldset>

                            {props.isFormEditable &&
                                <DefaultButton
                                    className='addDependentBTN'
                                    iconProps={{ iconName: 'AddTo' }}
                                    text={t('UserDependentDetail.ActionButton.AddDependent')}
                                    onClick={() => {
                                        _setPanelOptions({
                                            PanelHeader: t('UserDependentDetail.Panel.AddDependentHeader'),
                                            IsPanelOpen: true,
                                            SaveBTNText: t('UserDependentDetail.FormFields.AddDependentFormFields.ActionButton.AddDependent')
                                        });
                                        _setUserDependentInfo(null);
                                    }}
                                    allowDisabledFocus />
                            }
                        </StackItem>
                    </Stack>
                </StackItem>

            </Stack>
        </>
    );
};
