import { IDropdownOption } from "@fluentui/react";
import { AttachmentComponent, IFileInfo, ITexts } from "ccs-azure";
import {
  IAppFileInfo,
  IChoiceState,
  IReturnAction,
  IUserAdditionalData,
  IUserBasicData,
  IUserDependentInfo,
  IUserDoc1Data,
  IUserDoc2Data,
  IUserFieldsState,
  IUserProfileData,
  IUserProfileObj,
} from "Interfaces/IHRBrazil";
import { SaveTypeEnum, ValidationType } from "./AppEnums";
import { AppConstants } from "./Constants";
import i18next, { t } from "i18next";
import { v4 as uuidv4 } from "uuid";

const Utility = {
  getAttachmentTexts: (): ITexts => {
    return {
      ...AttachmentComponent.defaultProps.texts,
      dropzoneToolTip: i18next.t("home:AttachmentTexts.dropzoneToolTip"),
      labelLoading: i18next.t("home:AttachmentTexts.labelLoading"),
      labelAddingFile: i18next.t("home:AttachmentTexts.labelAddingFile"),
      labelDeletingFile: i18next.t("home:AttachmentTexts.labelDeletingFile"),
      labelExchangingFile: i18next.t("home:AttachmentTexts.labelExchangingFile"),
      labelAddFile: i18next.t("home:AttachmentTexts.labelAddFile"),
      labelReplaceFile: i18next.t("home:AttachmentTexts.labelReplaceFile"),
      labelDeleteConfirmation: i18next.t("home:AttachmentTexts.labelDeleteConfirmation"),
      labelButtonDelete: i18next.t("home:AttachmentTexts.labelButtonDelete"),
      labelButtonCancel: i18next.t("home:AttachmentTexts.labelButtonCancel"),
      labelDeleteConfirmationQuestion: i18next.t("home:AttachmentTexts.labelDeleteConfirmationQuestion"),
      labelWrongFileTypeTitle: i18next.t("home:AttachmentTexts.labelWrongFileTypeTitle"),
      labelButtonOk: i18next.t("home:AttachmentTexts.labelButtonOk"),
      labelAllowedExtensionsTitle: i18next.t("home:AttachmentTexts.labelAllowedExtensionsTitle"),
      labelNotUploadedFilesTitle: i18next.t("home:AttachmentTexts.labelNotUploadedFilesTitle"),
      labelForbiddenCharactersTitle: i18next.t("home:AttachmentTexts.labelForbiddenCharactersTitle"),
      labelButtonReplace: i18next.t("home:AttachmentTexts.labelButtonReplace"),
      labelReplaceConfirmationQuestion: i18next.t("home:AttachmentTexts.labelReplaceConfirmationQuestion"),
    };
  },
  mandatoryValidation: (): string => {
    return i18next.t("home:LoginForm.Messages.FieldIsMandatory");
  },
  emailValidation: (): string => {
    return i18next.t("home:LoginForm.Messages.EnterValidEmail");
  },
  getDependentUserDocs: (dependentUserInfo: IUserDependentInfo): IFileInfo[] => {
    let docsNeedToBeDeleted: IFileInfo[] = [];
    if (dependentUserInfo.BCertificate) {
      dependentUserInfo.BCertificate.forEach((doc) => {
        docsNeedToBeDeleted.push({
          allowDelete: true,
          fileId: doc.fileId,
          fileName: doc.fileName,
        });
      });
    }
    if (dependentUserInfo.CPF.Doc) {
      dependentUserInfo.CPF.Doc.forEach((doc) => {
        docsNeedToBeDeleted.push({
          allowDelete: true,
          fileId: doc.fileId,
          fileName: doc.fileName,
        });
      });
    }
    if (dependentUserInfo.RG.Doc) {
      dependentUserInfo.RG.Doc.forEach((doc) => {
        docsNeedToBeDeleted.push({
          allowDelete: true,
          fileId: doc.fileId,
          fileName: doc.fileName,
        });
      });
    }
    return docsNeedToBeDeleted;
  },
  CleanSession: () => {
    window.sessionStorage.removeItem(AppConstants.Storage.authToken);
    window.sessionStorage.removeItem(AppConstants.Storage.appSession);
  },
  SaveSession: (authData: any, sessionData: any) => {
    window.sessionStorage.setItem(AppConstants.Storage.authToken, JSON.stringify(authData));
    window.sessionStorage.setItem(AppConstants.Storage.appSession, JSON.stringify(sessionData));
  },
  IsLoginUserActive: (callLogoutfunction: any) => {
    let IsLoginUserActive = false;
    if (window.sessionStorage[AppConstants.Storage.authToken]) {
      let authToken = JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]);
      let expirationDate = new Date(authToken.expirationUTC);
      // console.log(expirationDate);
      if (expirationDate < new Date()) {
        // this token is expired. Clean it up
        if (callLogoutfunction != null) {
          callLogoutfunction();
        } else {
          Utility.CleanSession();
          // alert("Your Session expired. Please sign-in again.");
          alert(i18next.t("home:Error.SessionExpiredError"));
        }
        IsLoginUserActive = false;
      } else {
        IsLoginUserActive = true;
      }
    } else {
      alert(i18next.t("home:Error.SessionExpiredError"));
    }
    if (IsLoginUserActive == false) {
      Utility.redirectHome();
    }
    return IsLoginUserActive;
  },
  IsUnauthorizeError: (error: string, appContext: any) => {
    let isUnauthorize = false;
    if (error == "401") {
      isUnauthorize = true;
      Utility.CleanSession();
      if (appContext != null) {
        appContext.ShowAppModalWithFun(i18next.t("home:ModalDialogHeader.Error"), i18next.t("home:Error.SessionExpiredError"), Utility.redirectHome);
      } else {
        alert(i18next.t("home:Error.SessionExpiredError"));
        Utility.redirectHome();
      }
    }
    return isUnauthorize;
  },
  GetChoiceData: (field: string, dataColl: any) => {
    let returnData: Array<IChoiceState> = [];
    try {
      let filteredData = dataColl.filter((data: any) => data.title === field);
      if (filteredData.length > 0) {
        JSON.parse(filteredData[0].choices).forEach((element: any) => {
          if (element[AppConstants.languageSupport.en] && element[AppConstants.languageSupport.ptBR]) {
            returnData.push({
              en: element[AppConstants.languageSupport.en],
              br: element[AppConstants.languageSupport.ptBR],
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
      returnData = [];
    }
    return returnData;
  },
  GetChoiceOptions: (language: string, props: any, choiceType: string) => {
    let retObj: IDropdownOption[] = [];
    if (props.currentUser?.AppChoices) {
      let ddlData = props.currentUser.AppChoices[choiceType];
      let key = "";
      let text = "";
      if (ddlData) {
        ddlData.forEach((element: any) => {
          key = element.en;
          if (language == AppConstants.languageSupport.ptBR) {
            text = element.br;
          } else {
            text = element.en;
          }
          if (key && text) {
            retObj.push({
              key: key,
              text: text,
            });
          }
        });
      }
    }
    return retObj;
  },
  GetFilesSize: (files: (IFileInfo | File)[]): number => {
    let filesSize = 0;

    files.forEach((file) => {
      if (file instanceof File) {
        filesSize += file.size / (1024 * 1024);
      }
    });
    return Number(filesSize.toFixed(2));
  },
  IsFilesSizeValid: (filesColl: (IFileInfo | File)[][]): boolean => {
    let filesSizeValid = true;
    filesColl.forEach((files) => {
      if (filesSizeValid) {
        let filesSize = 0;
        files.forEach((file) => {
          if (file instanceof File) {
            filesSize += file.size / (1024 * 1024);
          }
        });
        if (filesSize > 10) {
          filesSizeValid = false;
        }
      }
    });
    return filesSizeValid;
  },
  IsRequiredFormDocsPresent: (filesColl: (IFileInfo | File)[][]): boolean => {
    let isRequiredFormDocsPresent = true;
    filesColl.forEach((files) => {
      if (files.length == 0) {
        isRequiredFormDocsPresent = false;
      }
    });
    return isRequiredFormDocsPresent;
  },
  GetFiles: (files: IAppFileInfo[]): IFileInfo[] => {
    let filterFIles = files
      // .filter((x: any) => x.fileType == fileType)
      .map((y: any) => {
        return {
          allowDelete: true,
          fileId: y?.fileId,
          fileName: y?.fileName,
        } as IFileInfo;
      });
    return filterFIles;
  },
  GetInitialData: (formType: string, inputData: any = null): any => {
    let data = null;
    switch (formType) {
      case AppConstants.SectionKey.BasicData:
        data = inputData as IUserBasicData;
        let userBasicData: IUserBasicData = {
          BadgePhoto: data?.BadgePhoto ? data?.BadgePhoto : [],
          BirthDate: data?.BirthDate ? data.BirthDate : "",
          CovidVacine: data?.CovidVacine ? data?.CovidVacine : [],
          FatherName: data?.FatherName ? data.FatherName : "",
          FullName: data?.FullName ? data.FullName : "",
          Gender: data?.Gender ? data.Gender : "",
          MotherName: data?.MotherName ? data.MotherName : "",
        };
        return userBasicData;

      case AppConstants.SectionKey.AdditionalData:
        data = inputData as IUserAdditionalData;
        let userAdditionalData: IUserAdditionalData = {
          Nationality: data?.Nationality ? data.Nationality : "",
          Race: data?.Race ? data.Race : "",
          Telephone: data?.Telephone ? data.Telephone : "",
          Email: data?.Email ? data.Email : "",
          Birthplace: {
            Country: data?.Birthplace?.Country ? data.Birthplace.Country : "",
            State: data?.Birthplace?.State ? data.Birthplace.State : "",
            City: data?.Birthplace?.City ? data.Birthplace.City : "",
          },
          EducationLevel: data?.EducationLevel ? data.EducationLevel : "",
          EducationCertificates: data?.EducationCertificates ? data.EducationCertificates : [],
          OtherCertificates: data?.OtherCertificates ? data.OtherCertificates : [],
          MaritalStatus: data?.MaritalStatus ? data.MaritalStatus : "",
          MaritalCert: data?.MaritalCert ? data.MaritalCert : [],
          AddressVerification: {
            Country: data?.AddressVerification?.Country ? data.AddressVerification.Country : "",
            CEP: data?.AddressVerification?.CEP ? data.AddressVerification.CEP : "",
            State: data?.AddressVerification?.State ? data.AddressVerification.State : "",
            City: data?.AddressVerification?.City ? data.AddressVerification.City : "",
            District: data?.AddressVerification?.District ? data.AddressVerification.District : "",
            PublicArea: data?.AddressVerification?.PublicArea ? data.AddressVerification.PublicArea : "",
            Address: data?.AddressVerification?.Address ? data.AddressVerification.Address : "",
            Number: data?.AddressVerification?.Number ? data.AddressVerification.Number : "",
            Complement: data?.AddressVerification?.Complement ? data.AddressVerification.Complement : "",
          },
          ProofOfAddress: data?.ProofOfAddress ? data.ProofOfAddress : [],
        };
        return userAdditionalData;

      case AppConstants.SectionKey.Documents1:
        data = inputData as IUserDoc1Data;
        let userDocument1Data: IUserDoc1Data = {
          CPF: {
            Doc: data?.CPF?.Doc ? data.CPF.Doc : [],
            Number: data?.CPF?.Number ? data.CPF.Number : "",
          },
          NIS: {
            Doc: data?.NIS?.Doc ? data.NIS.Doc : [],
            Number: data?.NIS?.Number ? data.NIS.Number : "",
            EmissionDate: data?.NIS?.EmissionDate ? data.NIS.EmissionDate : "",
          },
          CTPS: {
            Doc: data?.CTPS?.Doc ? data.CTPS.Doc : [],
            Number: data?.CTPS?.Number ? data.CTPS.Number : "",
            Serie: data?.CTPS?.Serie ? data.CTPS.Serie : "",
            Digit: data?.CTPS?.Digit ? data.CTPS.Digit : "",
            State: data?.CTPS?.State ? data.CTPS.State : "",
            DispatchDate: data?.CTPS?.DispatchDate ? data.CTPS.DispatchDate : "",
          },
          Passport: {
            Passport: data?.Passport?.Passport ? data.Passport.Passport : [],
            Number: data?.Passport?.Number ? data.Passport.Number : "",
            IssuingAuthority: data?.Passport?.IssuingAuthority ? data.Passport.IssuingAuthority : "",
            EmissionDate: data?.Passport?.EmissionDate ? data.Passport.EmissionDate : "",
            ExpirationDate: data?.Passport?.ExpirationDate ? data.Passport.ExpirationDate : "",
            EmissionCountry: data?.Passport?.EmissionCountry ? data.Passport.EmissionCountry : "",
            EmissionState: data?.Passport?.EmissionState ? data.Passport.EmissionState : "",
          },
          NotBrazilian: {
            FNRegistration: data?.NotBrazilian?.FNRegistration ? data.NotBrazilian.FNRegistration : [],
            ArrivalDate: data?.NotBrazilian?.ArrivalDate ? data.NotBrazilian.ArrivalDate : "",
            VisaCondition: data?.NotBrazilian?.VisaCondition ? data.NotBrazilian.VisaCondition : "",
            FRN: data?.NotBrazilian?.FRN ? data.NotBrazilian.FRN : "",
            IssuingAuthority: data?.NotBrazilian?.IssuingAuthority ? data.NotBrazilian.IssuingAuthority : "",
            DispatchDate: data?.NotBrazilian?.DispatchDate ? data.NotBrazilian.DispatchDate : "",
            VisaValidity: data?.NotBrazilian?.VisaValidity ? data.NotBrazilian.VisaValidity : "",
            EmploymentValidity: data?.NotBrazilian?.EmploymentValidity ? data.NotBrazilian.EmploymentValidity : "",
            HaveBrazilianChildren: data?.NotBrazilian?.HaveBrazilianChildren ? data.NotBrazilian.HaveBrazilianChildren : "",
          },
          RG: {
            Doc: data?.RG?.Doc ? data.RG.Doc : [],
            Number: data?.RG?.Number ? data.RG.Number : "",
            IAuthority: data?.RG?.IAuthority ? data.RG.IAuthority : "",
            EDate: data?.RG?.EDate ? data.RG.EDate : "",
            EState: data?.RG?.EState ? data.RG.EState : "",
          },
        };
        return userDocument1Data;

      case AppConstants.SectionKey.Documents2:
        data = inputData as IUserDoc2Data;
        let userDocument2Data: IUserDoc2Data = {
          Voter: {
            VRNumber: data?.Voter?.VRNumber ? data.Voter.VRNumber : "",
            EZone: data?.Voter?.EZone ? data.Voter.EZone : "",
            Section: data?.Voter?.Section ? data.Voter.Section : "",
            EDate: data?.Voter?.EDate ? data.Voter.EDate : "",
            State: data?.Voter?.State ? data.Voter.State : "",
            City: data?.Voter?.City ? data.Voter.City : "",
            VDoc: data?.Voter?.VDoc ? data.Voter.VDoc : [],
          },
          CNH: {
            LDoc: data?.CNH?.LDoc ? data.CNH.LDoc : [],
            CNHNumber: data?.CNH?.CNHNumber ? data.CNH.CNHNumber : "",
            LCategory: data?.CNH?.LCategory ? data.CNH.LCategory : "",
            IAuthority: data?.CNH?.IAuthority ? data.CNH.IAuthority : "",
            EState: data?.CNH?.EState ? data.CNH.EState : "",
            EDate: data?.CNH?.EDate ? data.CNH.EDate : "",
            LValidity: data?.CNH?.LValidity ? data.CNH.LValidity : "",
            FirstLDate: data?.CNH?.FirstLDate ? data.CNH.FirstLDate : "",
          },
          Military: {
            CDoc: data?.Military?.CDoc ? data.Military.CDoc : [],
            CNumber: data?.Military?.CNumber ? data.Military.CNumber : "",
            Serie: data?.Military?.Serie ? data.Military.Serie : "",
            RANumber: data?.Military?.RANumber ? data.Military.RANumber : "",
            DDate: data?.Military?.DDate ? data.Military.DDate : "",
          },
          BankData: {
            BDoc: data?.BankData?.BDoc ? data.BankData.BDoc : [],
            Bank: data?.BankData?.Bank ? data.BankData.Bank : "",
            BAgency: data?.BankData?.BAgency ? data.BankData.BAgency : "",
            AccountType: data?.BankData?.AccountType ? data.BankData.AccountType : "",
            AccountNumber: data?.BankData?.AccountNumber ? data.BankData.AccountNumber : "",
            Digit: data?.BankData?.Digit ? data.BankData.Digit : "",
          },
        };
        return userDocument2Data;

      case AppConstants.SectionKey.DependentDetail:
        return [];

      case AppConstants.SectionKey.UserDependentInfo:
        data = inputData as IUserDependentInfo;
        let userDependentInfo: IUserDependentInfo = {
          DID: data?.DID ? data.DID : uuidv4(),
          FullName: data?.FullName ? data.FullName : "",
          Relationship: data?.Relationship ? data.Relationship : "",
          Gender: data?.Gender ? data.Gender : "",
          BDate: data?.BDate ? data.BDate : "",
          IsDependentForIncome: data?.IsDependentForIncome ? data.IsDependentForIncome : "",
          MName: data?.MName ? data.MName : "",
          mStatus: data?.mStatus ? data.mStatus : "",
          BCertificate: data?.BCertificate ? data.BCertificate : [],
          CPF: {
            Doc: data?.CPF?.Doc ? data.CPF.Doc : [],
            Number: data?.CPF.Number ? data.CPF.Number : "",
          },
          RG: {
            Doc: data?.RG.Doc ? data.RG.Doc : [],
            Number: data?.RG.Number ? data.RG.Number : "",
            IAuthority: data?.RG.IAuthority ? data.RG.IAuthority : "",
            EDate: data?.RG.EDate ? data.RG.EDate : "",
            EState: data?.RG.EState ? data.RG.EState : "",
          },
        };
        return userDependentInfo;

      default:
        break;
    }
  },
  IsFormUpdated: (isFilesUpdated: boolean, formType: string, actualUserProfile: IUserFieldsState, updatedProfileFormData: any): boolean => {
    let isFormDataDiffer = isFilesUpdated;
    if (isFormDataDiffer == false && actualUserProfile.FormData) {
      let userProfileData = JSON.parse(actualUserProfile.FormData) as IUserProfileData;
      switch (formType) {
        case AppConstants.SectionKey.BasicData:
          if (JSON.stringify(userProfileData.BasicData) != JSON.stringify(updatedProfileFormData)) {
            isFormDataDiffer = true;
          }
          break;
        case AppConstants.SectionKey.AdditionalData:
          if (JSON.stringify(userProfileData.AdditionalData) != JSON.stringify(updatedProfileFormData)) {
            isFormDataDiffer = true;
          }
          break;
        case AppConstants.SectionKey.Documents1:
          if (JSON.stringify(userProfileData.Doc1) != JSON.stringify(updatedProfileFormData)) {
            isFormDataDiffer = true;
          }
          break;
        case AppConstants.SectionKey.Documents2:
          if (JSON.stringify(userProfileData.Doc2) != JSON.stringify(updatedProfileFormData)) {
            isFormDataDiffer = true;
          }
          break;
        case AppConstants.SectionKey.DependentDetail:
          if (JSON.stringify(userProfileData.DependDetail) != JSON.stringify(updatedProfileFormData)) {
            isFormDataDiffer = true;
          }
          break;
        default:
          break;
      }
    }
    return isFormDataDiffer;
  },
  IsNotNullOrEmpty: (text: string): boolean => {
    let isEmpty = true;
    if (text) {
      isEmpty = false;
    }
    return isEmpty;
  },
  IsDocsEmpty: (docs: IAppFileInfo[]): boolean => {
    let isEmpty = true;
    if (docs && docs.length > 0) {
      isEmpty = false;
    }
    return isEmpty;
  },
  GetUserProfileFormat: (userProfileData: IUserProfileData): IUserProfileObj => {
    let userProfileObj: IUserProfileObj;
    let userDocs: string[] = [];
    if (userProfileData?.BasicData) {
      if (userProfileData.BasicData.BadgePhoto.length > 0) {
        userDocs.push(userProfileData.BasicData.BadgePhoto[0].fileId);
        userProfileData.BasicData.BadgePhoto = [userProfileData.BasicData.BadgePhoto[0]];
      }
      if (userProfileData.BasicData.CovidVacine.length > 0) {
        userDocs.push(userProfileData.BasicData.CovidVacine[0].fileId);
        userProfileData.BasicData.CovidVacine = [userProfileData.BasicData.CovidVacine[0]];
      }
    }
    if (userProfileData?.AdditionalData) {
      if (userProfileData.AdditionalData.EducationCertificates.length > 0) {
        userProfileData.AdditionalData.EducationCertificates.forEach((certificate) => {
          userDocs.push(certificate.fileId);
        });
      }
      if (userProfileData.AdditionalData.OtherCertificates?.length > 0) {
        userProfileData.AdditionalData.OtherCertificates.forEach((certificate) => {
          userDocs.push(certificate.fileId);
        });
      }
      if (userProfileData.AdditionalData.MaritalCert.length > 0) {
        userProfileData.AdditionalData.MaritalCert.forEach((certificate) => {
          userDocs.push(certificate.fileId);
        });
      }
      if (userProfileData.AdditionalData.ProofOfAddress.length > 0) {
        userDocs.push(userProfileData.AdditionalData.ProofOfAddress[0].fileId);
        userProfileData.AdditionalData.ProofOfAddress = [userProfileData.AdditionalData.ProofOfAddress[0]];
      }
    }
    if (userProfileData?.Doc1) {
      if (userProfileData.Doc1.CPF.Doc.length > 0) {
        userDocs.push(userProfileData.Doc1.CPF.Doc[0].fileId);
        userProfileData.Doc1.CPF.Doc = [userProfileData.Doc1.CPF.Doc[0]];
      }
      if (userProfileData.Doc1.NIS.Doc.length > 0) {
        userDocs.push(userProfileData.Doc1.NIS.Doc[0].fileId);
        userProfileData.Doc1.NIS.Doc = [userProfileData.Doc1.NIS.Doc[0]];
      }
      if (userProfileData.Doc1.CTPS.Doc.length > 0) {
        userDocs.push(userProfileData.Doc1.CTPS.Doc[0].fileId);
        userProfileData.Doc1.CTPS.Doc = [userProfileData.Doc1.CTPS.Doc[0]];
      }
      if (userProfileData.Doc1.Passport.Passport.length > 0) {
        userDocs.push(userProfileData.Doc1.Passport.Passport[0].fileId);
        userProfileData.Doc1.Passport.Passport = [userProfileData.Doc1.Passport.Passport[0]];
      }
      if (userProfileData.Doc1.RG?.Doc?.length > 0) {
        userDocs.push(userProfileData.Doc1.RG.Doc[0].fileId);
        userProfileData.Doc1.RG.Doc = [userProfileData.Doc1.RG.Doc[0]];
      }
      if (userProfileData.Doc1.NotBrazilian.FNRegistration.length > 0) {
        userDocs.push(userProfileData.Doc1.NotBrazilian.FNRegistration[0].fileId);
        userProfileData.Doc1.NotBrazilian.FNRegistration = [userProfileData.Doc1.NotBrazilian.FNRegistration[0]];
      }
    }
    if (userProfileData?.Doc2) {
      if (userProfileData.Doc2.CNH.LDoc.length > 0) {
        userDocs.push(userProfileData.Doc2.CNH.LDoc[0].fileId);
        userProfileData.Doc2.CNH.LDoc = [userProfileData.Doc2.CNH.LDoc[0]];
      }
      if (userProfileData.Doc2.Military.CDoc.length > 0) {
        userDocs.push(userProfileData.Doc2.Military.CDoc[0].fileId);
        userProfileData.Doc2.Military.CDoc = [userProfileData.Doc2.Military.CDoc[0]];
      }
      if (userProfileData.Doc2.BankData.BDoc.length > 0) {
        userDocs.push(userProfileData.Doc2.BankData.BDoc[0].fileId);
        userProfileData.Doc2.BankData.BDoc = [userProfileData.Doc2.BankData.BDoc[0]];
      }
      if (userProfileData.Doc2.Voter.VDoc?.length > 0) {
        userDocs.push(userProfileData.Doc2.Voter.VDoc[0].fileId);
        userProfileData.Doc2.Voter.VDoc = [userProfileData.Doc2.Voter.VDoc[0]];
      }
    }
    if (userProfileData?.DependDetail) {
      userProfileData?.DependDetail.forEach((dependDetail) => {
        if (dependDetail.BCertificate.length > 0) {
          userDocs.push(dependDetail.BCertificate[0].fileId);
          dependDetail.BCertificate = [dependDetail.BCertificate[0]];
        }
        if (dependDetail.CPF.Doc.length > 0) {
          userDocs.push(dependDetail.CPF.Doc[0].fileId);
          dependDetail.CPF.Doc = [dependDetail.CPF.Doc[0]];
        }
        if (dependDetail.RG.Doc.length > 0) {
          userDocs.push(dependDetail.RG.Doc[0].fileId);
          dependDetail.RG.Doc = [dependDetail.RG.Doc[0]];
        }
      });
    }
    userProfileObj = {
      UserDocs: userDocs,
      UserProfile: userProfileData,
    };
    return userProfileObj;
  },
  IsBasicFormValidated: (updatedProfileFormData: any, validteFieldOnly: ValidationType = ValidationType.All): boolean => {
    let isBasicDataValidated = true;
    let formData = updatedProfileFormData as IUserBasicData;
    if (Utility.IsNotNullOrEmpty(formData?.FullName)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Gender)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.BirthDate)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.MotherName)) {
      return false;
    }
    if (validteFieldOnly != ValidationType.FieldOnly) {
      if (Utility.IsDocsEmpty(formData?.BadgePhoto)) {
        return false;
      }
      if (Utility.IsDocsEmpty(formData?.CovidVacine)) {
        return false;
      }
    }
    return isBasicDataValidated;
  },

  IsAdditionalFormValidated: (updatedProfileFormData: any, validteFieldOnly: ValidationType = ValidationType.All): boolean => {
    let isAdditionalDataValidated = true;
    let formData = updatedProfileFormData as IUserAdditionalData;
    if (Utility.IsNotNullOrEmpty(formData?.Nationality)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Race)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Telephone)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Email)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Birthplace?.Country)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Birthplace?.State)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.Birthplace?.City)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.MaritalStatus)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.EducationLevel)) {
      return false;
    }

    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.Country)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.CEP)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.State)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.City)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.District)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.PublicArea)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.Address)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.Number)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.AddressVerification?.Complement)) {
      return false;
    }
    if (validteFieldOnly != ValidationType.FieldOnly) {
      if (Utility.IsDocsEmpty(formData?.ProofOfAddress)) {
        return false;
      }
      if (Utility.IsDocsEmpty(formData?.EducationCertificates)) {
        return false;
        }
        if (Utility.IsDocsEmpty(formData?.OtherCertificates)) {
            return false;
        }
      if (formData?.MaritalStatus != AppConstants.maritalStatus.Single) {
        if (Utility.IsDocsEmpty(formData?.MaritalCert)) {
          return false;
        }
      }
    }
    return isAdditionalDataValidated;
  },
  IsDoc1FormValidated: (updatedProfileFormData: any, validteFieldOnly: ValidationType = ValidationType.All): boolean => {
    let isDoc1DataValidated = true;
    let formData = updatedProfileFormData as IUserDoc1Data;

    if (Utility.IsNotNullOrEmpty(formData?.CPF?.Number)) {
      return false;
    }

    if (Utility.IsNotNullOrEmpty(formData?.NIS?.Number)) {
      return false;
    }

    if (Utility.IsNotNullOrEmpty(formData?.CTPS?.Number)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.CTPS?.Serie)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData?.CTPS?.State)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData.RG.Number)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData.RG.EDate)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData.RG.EState)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(formData.RG.IAuthority)) {
      return false;
    }
    if (validteFieldOnly != ValidationType.FieldOnly) {
      if (Utility.IsDocsEmpty(formData?.CPF?.Doc)) {
        return false;
      }
      if (Utility.IsDocsEmpty(formData?.CTPS.Doc)) {
        return false;
      }
      if (Utility.IsDocsEmpty(formData?.NIS?.Doc)) {
        return false;
      }
    }
    return isDoc1DataValidated;
  },
  IsDoc2FormValidated: (updatedProfileFormData: any, userProfile: IUserProfileData, validteFieldOnly: ValidationType = ValidationType.All): boolean => {
    let isDoc2DataValidated = true;
    let formData = updatedProfileFormData as IUserDoc2Data;
    if (Utility.IsMCertificateRequire(userProfile?.BasicData?.Gender)) {
      if (Utility.IsDocsEmpty(formData?.Military.CDoc)) {
        return false;
      }
    }
    return isDoc2DataValidated;
  },
  IsDependentDetailsFormValidated: (updatedProfileFormData: any): boolean => {
    return true;
  },
  IsUserDependentInfoValidated: (userDependentInfo: IUserDependentInfo, validteFieldOnly: ValidationType = ValidationType.All): boolean => {
    let IsDataValidated = true;
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.FullName)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.Relationship)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.Gender)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.BDate)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.MName)) {
      return false;
    }
    if (Utility.IsNotNullOrEmpty(userDependentInfo?.mStatus)) {
      return false;
    }

    if (Utility.IsNotNullOrEmpty(userDependentInfo?.CPF?.Number)) {
      return false;
    }

    // Change 2024-05-28 Daniel: These 4 fields shouldn't be mandatory anymore
    //if (Utility.IsNotNullOrEmpty(userDependentInfo?.RG?.Number)) {
    //    return false;
    //}
    //if (Utility.IsNotNullOrEmpty(userDependentInfo?.RG?.IAuthority)) {
    //    return false;
    //}
    //if (Utility.IsNotNullOrEmpty(userDependentInfo?.RG?.EDate)) {
    //    return false;
    //}
    //if (Utility.IsNotNullOrEmpty(userDependentInfo?.RG?.EState)) {
    //    return false;
    //}

    if (validteFieldOnly != ValidationType.FieldOnly) {
      if (Utility.IsDocsEmpty(userDependentInfo?.BCertificate)) {
        return false;
      }
      if (Utility.IsDocsEmpty(userDependentInfo?.CPF?.Doc)) {
        return false;
      }
      // if (Utility.IsDocsEmpty(userDependentInfo?.RG?.Doc)) {
      //     return false;
      // }
    }
    return IsDataValidated;
  },
  IsMCertificateRequire(gender: string): boolean {
    return gender ? (gender == "Male" ? true : false) : false;
  },
  ValidationWithErrorForm: (formType: string, updatedProfileFormData: any, userProfile: IUserProfileData): string => {
    let errorFormKey = "";
    switch (formType) {
      case AppConstants.SectionKey.BasicData:
        if (!Utility.IsBasicFormValidated(updatedProfileFormData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.BasicData");
        }
        break;
      case AppConstants.SectionKey.AdditionalData:
        if (!Utility.IsAdditionalFormValidated(updatedProfileFormData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.AdditionalInformation");
        }
        break;
      case AppConstants.SectionKey.Documents1:
        if (!Utility.IsDoc1FormValidated(updatedProfileFormData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.Documents1");
        }
        break;
      case AppConstants.SectionKey.Documents2:
        if (!Utility.IsDoc2FormValidated(updatedProfileFormData, userProfile)) {
          errorFormKey = i18next.t("home:UserProfileOptions.Documents2");
        }
        break;
      case AppConstants.SectionKey.DependentDetail:
        if (!Utility.IsDependentDetailsFormValidated(updatedProfileFormData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.DependentDetail");
        }
        break;
      case AppConstants.SectionKey.AllFormData:
        let userProfileData = updatedProfileFormData as IUserProfileData;
        if (!Utility.IsBasicFormValidated(userProfileData.BasicData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.BasicData");
        }
        if (errorFormKey === "" && !Utility.IsAdditionalFormValidated(userProfileData.AdditionalData)) {
          errorFormKey = i18next.t("home:UserProfileOptions.AdditionalInformation");
        }
        if (errorFormKey === "" && !Utility.IsDoc1FormValidated(userProfileData.Doc1)) {
          errorFormKey = i18next.t("home:UserProfileOptions.Documents1");
        }
        if (errorFormKey === "" && !Utility.IsDoc2FormValidated(userProfileData.Doc2, userProfile)) {
          errorFormKey = i18next.t("home:UserProfileOptions.Documents2");
        }
        if (errorFormKey === "" && !Utility.IsDependentDetailsFormValidated(userProfileData.DependDetail)) {
          errorFormKey = i18next.t("home:UserProfileOptions.DependentDetail");
        }
        break;
      default:
        break;
    }
    return errorFormKey;
  },

  GetActionProps: (saveType: SaveTypeEnum, preCall: any, nextCall: any): IReturnAction => {
    let callBackFunc = null;
    let saveRequired = false;
    switch (saveType) {
      case SaveTypeEnum.NextSave:
        callBackFunc = nextCall;
        saveRequired = true;
        break;
      case SaveTypeEnum.Next:
        callBackFunc = nextCall;
        break;
      case SaveTypeEnum.BackSave:
        callBackFunc = preCall;
        saveRequired = true;
        break;
      case SaveTypeEnum.Back:
        callBackFunc = preCall;
        break;
      default:
        console.log("Error on calling function for enum: " + saveType);
        alert("Error on calling function for enum: " + saveType);
        break;
    }
    let retObj: IReturnAction = {
      callBack: callBackFunc,
      saveRequired: saveRequired,
    };
    return retObj;
  },
  pad: (number: number) => {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  },
  GetClientDateTimeFormat: (dt: Date) => {
    return dt
      ? Utility.pad(dt.getDate()) +
          "." +
          Utility.pad(dt.getMonth() + 1) +
          "." +
          dt.getFullYear() +
          "  " +
          Utility.pad(dt.getHours()) +
          "." +
          Utility.pad(dt.getMinutes())
      : "";
  },
  GetClientDateFormat: (dt: Date) => {
    return dt ? Utility.pad(dt.getDate()) + "/" + Utility.pad(dt.getMonth() + 1) + "/" + dt.getFullYear() : "";
  },
  // CreateCAMLDateTimeFormat: (dt: Date) => {
  //     return dt.getFullYear() +
  //         '-' + Utility.pad(dt.getMonth() + 1) +
  //         '-' + Utility.pad(dt.getDate()) +
  //         'T' + Utility.pad(dt.getHours()) +
  //         ':' + Utility.pad(dt.getMinutes()) +
  //         ':' + Utility.pad(dt.getSeconds()) +
  //         'Z';
  // },
  // CreateCAMLDateFormat: (dt: Date) => {
  //     return dt.getFullYear() +
  //         '-' + Utility.pad(dt.getMonth() + 1) +
  //         '-' + Utility.pad(dt.getDate()) +
  //         'T00:00:00Z';
  // },
  // addBlockBackgroundClass: () => {
  //     /// code to remove multiple scroll after closing modal on panel
  //     try {
  //         var obj = document.getElementsByTagName("body");
  //         if (!obj[0].classList.contains("blockBackground")) {
  //             obj[0].classList.add("blockBackground");
  //         }
  //     }
  //     catch (err) { }
  // },
  // removeBlockBackgroundClass: () => {
  //     /// code to remove multiple scroll after closing modal on panel
  //     try {
  //         var obj = document.getElementsByTagName("body");
  //         if (obj[0].classList.contains("blockBackground")) {
  //             obj[0].classList.remove("blockBackground");
  //         }
  //     }
  //     catch (err) { }
  // },
  // removeEmptyDivIfPresent: () => {
  //     /// code to remove multiple scroll after closing modal on panel
  //     try {
  //         var obj = document.getElementById("fluent-default-layer-host");
  //         if (!obj?.innerHTML) {
  //             obj?.remove();
  //         }
  //     }
  //     catch (err) { }
  // },
  // getTabDueToParameter: () => {
  //     const url = new URL(window.location.href);
  //     const params = new URLSearchParams(url.search);
  //     let userParam: string | null;
  //     let eventParam: string | null;
  //     params.has('user') ? userParam = params.get("user") : userParam = "";
  //     params.has('event') ? eventParam = params.get("event") : eventParam = "";
  //     var tabOrder = -1;
  //     if (userParam) {
  //         tabOrder = 2;
  //     }
  //     if (eventParam) {
  //         tabOrder = 0;
  //     }
  //     return tabOrder;
  // },
  // getDefaultEvent: () => {
  //     const url = new URL(window.location.href);
  //     const params = new URLSearchParams(url.search);
  //     let eventParam: string | null;
  //     params.has('event') ? eventParam = params.get("event") : eventParam = "";
  //     return eventParam;
  // },
  getDefaultUser: () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    let userParam: string | null;
    params.has("user") ? (userParam = params.get("user")) : (userParam = "");
    return userParam;
  },
  redirectHome: () => {
    window.location.replace(window.location.origin);
  },
  isUserUpdateServiceCallRequired: (original: IUserFieldsState, userObj: IUserFieldsState) => {
    let callRequired = false;
    if (original) {
      if (
        original.Email != userObj.Email ||
        original.FirstName != userObj.FirstName ||
        original.LastName != userObj.LastName ||
        original.Password != userObj.Password ||
        original.Status != userObj.Status ||
        original.Validated != userObj.Validated ||
        original.HiringDate != userObj.HiringDate
      ) {
        callRequired = true;
      }
    } else {
      callRequired = true;
    }

    return callRequired;
  },
  IsValidateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  },
};
export { Utility };
