import { css, Checkbox, DefaultButton, IconButton, Label, mergeStyles, MessageBar, MessageBarType, Spinner, Stack, TextField, StackItem } from '@fluentui/react';
import * as React from 'react';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { AccountType, IAuthenticatedUser, IUserAccount } from './LoginComponent';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import Button from "react-bootstrap/Button";
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Common } from './Common';
// import { Badge, BadgeProps } from 'react-bootstrap';
import { AppConstants } from 'components/Utility/Constants';
import { Utility } from 'components/Utility/Utility';
import i18next, { t } from 'i18next';


require('./LoginComponent.css');


export interface ICustomAuthComponentProps {
    dataProvider: IDataProvider;
    setAuthStatus: (loggin: boolean, user: IAuthenticatedUser | null) => Promise<void>;
    currentUser: IAuthenticatedUser | null;
}

export interface ICustomAuthComponentState {
    currentUser: IUserAccount | null;
    emailErrorMessage: string;
    passwordErrorMessage: string;
    informationType?: MessageBarType;
    informationMessage?: string;
    loggedIn: boolean;
    isProcessing: boolean;
    reCaptchaError: boolean;
    reCaptchaToken: string | null;
    isValidated: boolean;
}

export class ICustomAuthComponent extends React.Component<ICustomAuthComponentProps, ICustomAuthComponentState> {
    private refReCaptchaRegister: React.RefObject<ReCAPTCHA> | null = null;
    private refReCaptchaLogin: React.RefObject<ReCAPTCHA> | null = null;

    constructor(props: ICustomAuthComponentProps) {
        super(props);
        this.refReCaptchaRegister = React.createRef<ReCAPTCHA>();
        this.refReCaptchaLogin = React.createRef<ReCAPTCHA>();
        let loggedIn = false;
        let user: IUserAccount = {
            firstName: "",
            lastName: "",
            email: "",
            accountType: AccountType.Custom,
            password: "",
            userId: "",
            status: ""
        }

        if (props.currentUser) {
            user = props.currentUser;
            loggedIn = true;
        }

        this.state = {
            currentUser: user,
            emailErrorMessage: "",
            passwordErrorMessage: "",
            loggedIn: loggedIn,
            isProcessing: false,
            reCaptchaError: false,
            reCaptchaToken: null,
            isValidated: false
        };

    }
    public componentDidMount() {

    }

    public componentWillReceiveProps(newProps: ICustomAuthComponentProps) {
        if (this.props.currentUser != newProps.currentUser) {
            let user: IUserAccount | null = newProps.currentUser;
            if (!newProps.currentUser) {
                user = {
                    status: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    accountType: AccountType.Custom,
                    password: "",
                    userId: ""
                }
            }
            this.setState({
                loggedIn: Boolean(newProps.currentUser),
                currentUser: user
            });
        }
    }


    private _onLoginBtnClicked = () => {
        try {
            if (this.inputsAreaValid(false)) {
                // check if email allready exist
                this.loginUser();
            } else {
                this.setInformationMessage(i18next.t('home:LoginForm.Messages.ValidationIssue'), MessageBarType.error);
            }
        } catch (err) {
            console.log("Error: " + err);
            alert(i18next.t('home:LoginForm.LoginClickError'));
        }
    }
    private _onLogoutBtnClicked = () => {
        try {
            this.setState({
                currentUser: {
                    status: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    accountType: AccountType.Custom,
                    password: "",
                    userId: ""
                },
                // registered: false,
                loggedIn: false
            }, () => {
                this.props.setAuthStatus(false, null);
            });

        } catch (err) {
            console.log("Error: " + err);
            alert(i18next.t('home:LoginForm.LogoutClickError'));
        } finally {
            Utility.CleanSession();
        }
    }



    private loginUser = () => {
        this.setState({ isProcessing: true, informationMessage: "" });
        this.props.dataProvider.loginExternalUserAccount(this.state.currentUser as IUserAccount, this.state.reCaptchaToken ? this.state.reCaptchaToken : "")
            .then((user: any) => {
                if (user.hasError) {
                    Utility.CleanSession();
                    let autoDismissError = true;
                    let defaultMessage = i18next.t('home:LoginForm.Messages.ExternalUserAvailableIssue');
                    let errormessage = user.errorDetail;
                    if (errormessage == "Account is not active") {
                        // augment error with confirmation hyperlink
                        defaultMessage = i18next.t('home:LoginForm.Messages.AccountActiveIssue');
                        autoDismissError = false;
                    } else if (errormessage == "reCaptcha Token expired or is invalid") {
                        defaultMessage = i18next.t('home:LoginForm.Messages.RecaptchaExpired');
                        autoDismissError = false;
                    }
                    alert(defaultMessage);
                    console.log(errormessage);
                    this.refReCaptchaLogin?.current?.reset();
                    this.setInformationMessage(defaultMessage, MessageBarType.error, autoDismissError);
                }
                else {
                    if (user.data.accountType == AccountType.Custom) {
                        Utility.SaveSession(user.data.bearerToken, user.data);
                        this.setInformationMessage(i18next.t('home:LoginForm.Messages.LoginSuccessfull'), MessageBarType.success);
                        this.setState({ loggedIn: true });
                        this.setState({ currentUser: user.data });
                        this.props.setAuthStatus(true, user.data);
                    }
                    else {
                        let externalUserIssue = i18next.t('home:LoginForm.Messages.ExternalUserIssue');
                        console.log(externalUserIssue);
                        alert(externalUserIssue);
                        Utility.CleanSession();
                        this.refReCaptchaLogin?.current?.reset();
                    }
                }

            }).catch(err => {
                let externalUserValidationIssue = i18next.t('home:LoginForm.Messages.ExternalUserAPIIssue');
                console.log(externalUserValidationIssue);
                alert(externalUserValidationIssue);
                Utility.CleanSession();
                // reset reCaptcha, it's only valid for 1 serverVerify
                this.refReCaptchaLogin?.current?.reset();

            }).finally(() => {
                this.setState({ isProcessing: false });
            });
    }

    private onReCaptchaChange = (value: string) => {
        console.log("ReCaptcha value: " + value)
    };

    private inputsAreaValid = (register: boolean): boolean => {
        this.setState({
            emailErrorMessage: "",
            passwordErrorMessage: "",
            reCaptchaError: false
        });
        let invalid = false;
        if (register) {
            if (!this.state?.currentUser?.password || this.state?.currentUser?.password?.trim().length === 0) {
                invalid = true;
                this.setState({ passwordErrorMessage: Utility.mandatoryValidation() });
            }


            if (!this.refReCaptchaRegister?.current?.getValue()) {
                invalid = true;
                this.setState({ reCaptchaError: true });
            }
        } else {
            if (!this.refReCaptchaLogin?.current?.getValue()) {
                invalid = true;
                this.setState({ reCaptchaError: true });
            }
        }

        if (!this.state?.currentUser?.email || this.state?.currentUser?.email?.trim().length == 0) {
            invalid = true;
            this.setState({ emailErrorMessage: Utility.mandatoryValidation() });
        } else if (this.state?.currentUser?.email?.indexOf("@") == -1) {
            invalid = true;
            this.setState({ emailErrorMessage: Utility.emailValidation() });
        }
        if (!this.state?.currentUser?.password || this.state?.currentUser?.password?.trim().length === 0) {
            invalid = true;
            this.setState({ passwordErrorMessage: Utility.mandatoryValidation() });
        }

        this.setState({ isValidated: true });

        return !invalid;
    }

    private resetValidation = (): void => {
        this.setState({
            emailErrorMessage: "",
            passwordErrorMessage: "",
            informationMessage: "",
            reCaptchaError: false,
            isValidated: false
        });

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser as IUserAccount,
                email: "",
                password: ""
            }
        }));
    };

    private setInformationMessage = (message: string, type?: MessageBarType, autoDismiss: boolean = true): void => {
        if (autoDismiss) {
            this.setState(
                {
                    informationMessage: message,
                    informationType: type || MessageBarType.warning
                },
                () => {
                    setTimeout(() => { this.setState({ informationMessage: "" }); }, 4000)
                });
        } else {
            this.setState({ informationMessage: message, informationType: type || MessageBarType.warning });
        }
    }

    public render(): React.ReactElement<ICustomAuthComponentProps> {
        let userData = (this.state.currentUser) as any;
        const styleError: string = mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });

        let userName = this.props.currentUser ? `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}` : "";
        let currentUser = window.sessionStorage[AppConstants.Storage.appSession] ? JSON.parse(window.sessionStorage[AppConstants.Storage.appSession]) : null;
        userName = currentUser?.displayName ? currentUser.displayName : userName;

        return (
            <>
                {
                    this.state.loggedIn
                        ?
                        <div>{i18next.t('home:LoginForm.WelcomeUserPretext')}:<br /><span className='loginDisplayName'>{`${userName}`}</span><br /><Button variant="secondary" className="customButton" style={{ marginTop: '10px' }} onClick={this._onLogoutBtnClicked}>{i18next.t('home:LoginForm.LogoutText')}</Button></div>
                        :
                        <div>
                            <fieldset style={{ border: '1px solid #ddd', backgroundColor: 'white', padding: '20px', position: 'relative', marginTop: '25px', borderRadius: '5px', boxShadow: '1px 3px 2px 0px rgb(0, 0, 0, 0.15)' }}>
                                <legend className='legentStyle'>{i18next.t('home:LoginForm.CustomLoginHeader')}</legend>
                                <div>
                                    <Stack>
                                        <StackItem >
                                            <div dangerouslySetInnerHTML={{ __html: i18next.t('home:LoginForm.CustomLoginDesc') }}>
                                            </div>
                                        </StackItem>
                                    </Stack>
                                    <TextField
                                        label={i18next.t('home:LoginForm.Fields.EMailAddress')}
                                        autoComplete="off"
                                        placeholder={i18next.t('home:LoginForm.Fields.EMailAddressWatermark')}
                                        errorMessage={this.state.emailErrorMessage}
                                        onChange={(event, newValue?) => {
                                            this.setState(prevState => ({
                                                currentUser: {
                                                    ...prevState.currentUser as IUserAccount,
                                                    email: newValue || ""
                                                },
                                                emailErrorMessage: ""
                                            }), () => {
                                                if (this.state.isValidated) { this.inputsAreaValid(false); }
                                            });
                                        }} defaultValue={this.state?.currentUser?.email ? this.state?.currentUser?.email : ""}></TextField>
                                    <TextField
                                        label={i18next.t('home:LoginForm.Fields.SecurityCode')}
                                        type="password"
                                        autoComplete="off"
                                        canRevealPassword
                                        placeholder={i18next.t('home:LoginForm.Fields.SecurityCodeWatermark')}
                                        errorMessage={this.state.passwordErrorMessage}
                                        onChange={(event, newValue?) => {
                                            this.setState(prevState => ({
                                                currentUser: {
                                                    ...prevState.currentUser as IUserAccount,
                                                    password: newValue || ""
                                                },

                                            }), () => {
                                                if (this.state.isValidated) { this.inputsAreaValid(false); }
                                            });
                                        }} defaultValue={this.state?.currentUser?.password}></TextField>
                                    <Stack style={{ border: this.state.reCaptchaError ? '1px solid red' : 'none', padding: '5px 0px 5px 0px' }}>
                                        <ReCAPTCHA
                                            ref={this.refReCaptchaLogin}
                                            sitekey={AppConstants.capchaKey}
                                            onChange={(token: string | null) => {
                                                this.setState({
                                                    reCaptchaToken: token
                                                });
                                                if (this.state.isValidated) {
                                                    this.inputsAreaValid(false);
                                                }
                                            }}
                                        />
                                        {this.state.reCaptchaError && <Label className={css(styleError, 'freelanceError')}>{i18next.t('home:LoginForm.Fields.recaptaNoSelected')}</Label>}
                                    </Stack>
                                    <div style={{ display: 'flex', gap: '10px' }}><DefaultButton disabled={this.state.isProcessing} className={"customButton"} onClick={this._onLoginBtnClicked}>{i18next.t('home:LoginForm.Fields.LoginBtn')}</DefaultButton>{this.state.isProcessing && <Spinner />}</div><br />
                                    {
                                        this.state.informationMessage ?
                                            <MessageBar
                                                messageBarType={this.state.informationType || MessageBarType.warning}
                                                isMultiline={false}
                                                dismissButtonAriaLabel="Close"
                                            >{this.state.informationMessage}</MessageBar> : ""
                                    }
                                </div>
                            </fieldset>
                        </div>
                }
            </>

        );
    }
}

