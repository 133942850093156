import * as React from 'react';

export const AppTopContext = React.createContext({
    ShowAppLoader: (showLoader: boolean, msg: any) => { },
    ShowAppModal: (header: string, message: string) => { },
    ShowAppModalWithFun: (header: string, message: string, func: any) => { },
    ShowAppConfirmationModal: (header: string, message: string, okBTN: string, cancelBTN: string, func: any) => { },
    ExpireSession: null,
    IsLBrazil: false,
    IsUserAdmin: false
});
// export default UserContext;