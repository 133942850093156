import { AppConstants } from "components/Utility/Constants";
import { Utility } from "components/Utility/Utility";

var FileSaver = require('file-saver');
export default class HttpService {

    public static async get(url: string, body?: any, headers?: any, checkUserLoginState?: boolean): Promise<any> {
        let isUserLoginValid = true;
        if (isUserLoginValid) {
            if (checkUserLoginState != false) {
                isUserLoginValid = Utility.IsLoginUserActive(null);
            }
            return new Promise(async (resolve, reject) => {
                let response: Response;

                let baseHeaders = {
                    'Accept': 'application/json'
                };

                try {
                    // add bearer if present
                    if (window.sessionStorage[AppConstants.Storage.authToken]) {
                        let authToken = JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]);
                        (<any>baseHeaders)["Authorization"] = `Bearer ${authToken.token}`;
                    }

                    if (headers) {
                        baseHeaders = { ...baseHeaders, ...headers };
                    }

                    if (body)
                        response = await fetch(url, {
                            method: 'GET',
                            cache: 'no-cache',
                            headers: baseHeaders,
                            body: JSON.stringify(body)
                        });
                    else
                        response = await fetch(url, {
                            method: 'GET',
                            cache: 'no-cache',
                            headers: baseHeaders,
                        });

                    if (response.ok)
                        return response.json().then((result) => {
                            resolve(result);
                        });
                    else if (response.status == 401) {
                        Utility.CleanSession();
                        reject("Authentication Token Expired. Please sign in again.");
                    }
                    else if (response.status == 403) {
                        reject("Unauthorized");
                    }
                    else {
                        try {
                            return response.json().then((err) => {
                                reject(`Http Get Error: ${err?.title}`);
                            }).catch((ierr) => {
                                console.dir(ierr);
                                reject(ierr);
                            });
                        } catch (ex) {
                            reject("Unauthorized");
                        }
                    }
                } catch (ex) {
                    reject((<any>ex).message);
                }
            });
        }
        else {
            return new Promise(null);
        }
    }
    public static async getFileDownload(url: string, fileName: string, body?: any, headers?: any): Promise<boolean> {
        let isUserLoginValid = Utility.IsLoginUserActive(null);
        if (isUserLoginValid) {
            return new Promise(async (resolve, reject) => {
                let response: Response;
                let baseHeaders = {
                    'Accept': 'application/json'
                };
                try {
                    // add bearer if present
                    if (window.sessionStorage[AppConstants.Storage.authToken]) {
                        let authToken = JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]);
                        (<any>baseHeaders)["Authorization"] = `Bearer ${authToken.token}`;
                    }
                    if (headers) {
                        baseHeaders = { ...baseHeaders, ...headers };
                    }
                    if (body)
                        response = await fetch(url, {
                            method: 'GET',
                            cache: 'no-cache',
                            headers: baseHeaders,
                            body: JSON.stringify(body)
                        });
                    else
                        response = await fetch(url, {
                            method: 'GET',
                            cache: 'no-cache',
                            headers: baseHeaders,
                        });

                    if (response.ok) {
                        const data = await response.blob();
                        // Create a URL for the Blob object
                        // const url1 = URL.createObjectURL(data);

                        // // Create a link element with the download attribute and the blob URL as the href
                        // const link = document.createElement('a');
                        // link.href = url1;
                        // link.download = fileName;

                        // // Append the link element to the document body
                        // document.body.appendChild(link);
                        // link.click();

                        // // Remove the link element from the document body
                        // document.body.removeChild(link);
                        // resolve(true);

                        // const b: any = data;
                        // b.name = fileName;
                        // let file = new File([data], fileName, { lastModified: new Date().getTime(), type: data.type });
                        // const url1 = URL.createObjectURL(file);
                        // window.open(url1);
                        FileSaver.saveAs(data, fileName);
                        resolve(true);
                    }
                    else if (response.status == 401) {
                        Utility.CleanSession();
                        reject("Authentication Token Expired. Please sign in again.");
                    }
                    else if (response.status == 403) {
                        reject("Unauthorized");
                    }
                    else {
                        try {
                            return response.json().then((err) => {
                                reject(`Http Get Error: ${err?.title}`);
                            }).catch((ierr) => {
                                console.dir(ierr);
                                reject(ierr);
                            });
                        } catch (ex) {
                            reject("Unauthorized");
                        }
                    }
                } catch (ex) {
                    reject((<any>ex).message);
                }
            });
        }
        else {
            return new Promise(null);
        }
    }

    public static async post(url: string, body?: any, headers?: any, checkUserLoginState?: boolean): Promise<any> {
        let isUserLoginValid = true;
        if (isUserLoginValid) {
            if (checkUserLoginState != false) {
                isUserLoginValid = Utility.IsLoginUserActive(null);
            }
            return new Promise(async (resolve, reject) => {
                let baseHeaders = {
                    'Accept': 'application/json'
                };
                if (headers) {
                    baseHeaders = { ...baseHeaders, ...headers };
                }

                // only add an authorization header if it doesn't exist already
                if (!(<any>baseHeaders)["Authorization"] || (<any>baseHeaders)["Authorization"].length == 0) {
                    // add bearer if present
                    if (window.sessionStorage[AppConstants.Storage.authToken]) {
                        let authToken = JSON.parse(window.sessionStorage[AppConstants.Storage.authToken]);
                        (<any>baseHeaders)["Authorization"] = `Bearer ${authToken.token}`;
                    }
                }

                let response: Response;
                try {
                    if (body) {
                        response = await fetch(url, {
                            method: 'POST',
                            cache: 'no-cache',
                            headers: {
                                'Content-Type': 'application/json',
                                ...baseHeaders,
                            },
                            body: JSON.stringify(body)
                        });
                    } else {
                        response = await fetch(url, {
                            method: 'POST',
                            cache: 'no-cache',
                            headers: baseHeaders
                        });
                    }

                    if (response.ok)
                        return response.json().then((result) => {
                            resolve(result);
                        });
                    else if (response.status == 401) {
                        Utility.CleanSession();
                        reject("Authentication Token Expired. Please sign in again.");
                    }
                    else
                        return response.json().then((err) => {
                            let errorMessage = err;
                            if (err?.detail) {
                                errorMessage = err?.detail;
                            }
                            reject(errorMessage);
                        });
                } catch (err) {
                    let ex = err as any;
                    if (err && ex.message && ex.message.indexOf("Unauthorized") !== -1) {
                        Utility.CleanSession();
                        reject("Authentication Token Expired. Please sign in again.");
                    } else {
                        reject(ex.message);
                    }
                }
            });
        }
        else {
            return new Promise(null);
        }
    }

}