import { Checkbox, DefaultButton, Dropdown, IDropdownOption, Stack, TextField } from "@fluentui/react";
import { StackItem } from "@fluentui/react";
import { AdminPanelFormEnum, UserFieldEnum } from "components/Utility/AppEnums";
import { AppConstants } from "components/Utility/Constants";
import { Utility } from "components/Utility/Utility";
import { AppCalendar } from "components/Welcome/Common/AppCalendar";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import DataProvider from "dataprovider/DataProvider";
import { IUserFieldsState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
export interface IUserInformationFormProps {
  formType: AdminPanelFormEnum;
  itemID: number;
  cancelPanel: any;
}

export const UserInformationForm: React.FunctionComponent<IUserInformationFormProps> = ({ ...props }) => {
  let [_userOptions, _setUserOptions] = useState<IUserFieldsState>({
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    Status: AppConstants.UserFilterOptions.Draft,
    ProfileId: 0,
    FormData: "",
    UserId: "",
    Validated: false,
    HiringDate: undefined,
  });

  const [hiringDate, setHiringDate] = useState<Date | null | undefined>(null);

  let [originalUserValues, _setoriginalUserValues] = useState<IUserFieldsState>(null);
  let [_statusOptions, _setStatusOptions] = useState<IDropdownOption[]>([]);
  var dataProvider = new DataProvider();
  const appContext = useContext(AppTopContext);
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);

  useEffect(() => {
    if (props.itemID > 0) {
      appContext.ShowAppLoader(true, "");
      dataProvider
        .getUserProfileByID(props.itemID, false)
        .then((item) => {
          if (item.hasError) {
            console.log(item.errorDetail);
            if (!Utility.IsUnauthorizeError(item.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInGettingUserDataApiResult"));
            }
          } else {
            originalUserValues = {
              FirstName: item.data.firstName,
              LastName: item.data.lastName,
              Email: item.data.email.trim().toLowerCase().replaceAll(" ", ""),
              Password: item.data.password.trim().replaceAll(" ", ""),
              Status: item.data.status,
              ProfileId: item.data.profileId,
              FormData: "",
              UserId: item.data.userId,
              Validated: item.data.validated ? true : false,
              HiringDate: item.data.hiringDate,
            };
            _setUserOptions(originalUserValues);
            _setoriginalUserValues(originalUserValues);
          }
          appContext.ShowAppLoader(false, "");
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInGettingUserDataApiCall"));
          appContext.ShowAppLoader(true, "");
        });
    }
    var options = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.UserStatus);
    _setStatusOptions(options);
  }, [props.itemID, , appContext.IsLBrazil]);

  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: UserFieldEnum) {
    let userOptions = JSON.parse(JSON.stringify(_userOptions)) as IUserFieldsState;
    switch (fieldType) {
      case UserFieldEnum.FirstName:
        userOptions.FirstName = text;
        break;
      case UserFieldEnum.LastName:
        userOptions.LastName = text;
        break;
      case UserFieldEnum.Email:
        userOptions.Email = text.trim().toLowerCase().replaceAll(" ", "");
        break;
      case UserFieldEnum.Password:
        userOptions.Password = text.trim().replaceAll(" ", "");
        break;
      default:
        break;
    }
    _setUserOptions(userOptions);
  }

  function _onValidationChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
    const userOptions = JSON.parse(JSON.stringify(_userOptions)) as IUserFieldsState;
    userOptions.Validated = checked;
    _setUserOptions(userOptions);
  }

  function _onStatusChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
    let userOptions = JSON.parse(JSON.stringify(_userOptions)) as IUserFieldsState;
    userOptions.Status = item.key as string;
    _setUserOptions(userOptions);
  }

  function submitForm() {
    if (!Utility.IsValidateEmail(_userOptions.Email.trim())) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.EmailFormatRequired"));
      return;
    }
    if (_userOptions.FirstName.trim().length == 0) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FirstNameRequired"));
      return;
    }
    if (_userOptions.LastName.trim().length == 0) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.LastNameRequired"));
      return;
    }
    if (_userOptions.Email.trim().length == 0) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.EmailRequired"));
      return;
    }
    if (_userOptions.Password.trim().length < 4) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.PasswordRequired"));
      return;
    }
    if (_userOptions.Status.trim().length == 0) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.StatusRequired"));
      return;
    }
    if (!_userOptions.HiringDate) {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.HiringDateRequired"));
      return;
    }
    if (Utility.isUserUpdateServiceCallRequired(originalUserValues, _userOptions)) {
      appContext.ShowAppLoader(true, "");
      const _tempUserOptions = JSON.parse(JSON.stringify(_userOptions));

      if (hiringDate) {
        _tempUserOptions.HiringDate = new Date(Date.UTC(hiringDate.getFullYear(), hiringDate.getMonth(), hiringDate.getDate())).toISOString();
      }

      dataProvider
        .updateUserProfile(appContext.IsLBrazil, _userOptions)
        .then((output) => {
          if (output.hasError) {
            if (!Utility.IsUnauthorizeError(output.errorDetail, appContext)) {
              if (output.errorDetail == "100") {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("UserInformationForm.ErrorMsg.UserAlreadyExist"));
              } else {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("UserInformationForm.ErrorMsg.UserSaveIssue"));
              }
            }
          } else {
            if (_userOptions.ProfileId == 0) {
              appContext.ShowAppModalWithFun(t("ModalDialogHeader.Success"), t("UserInformationForm.SuccessMsg.NewUserCreated"), props.cancelPanel);
            } else {
              appContext.ShowAppModalWithFun(t("ModalDialogHeader.Success"), t("UserInformationForm.SuccessMsg.UserUpdated"), props.cancelPanel);
            }
          }
          appContext.ShowAppLoader(false, "");
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.ApiCallError.ErrorInUpdatingUserDataApiCall"));
          appContext.ShowAppLoader(true, "");
        });
    } else {
      appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.NoValueChangeFound"));
    }
  }
  return (
    <Stack className="userInformationSection">
      <StackItem className="header">{t("UserInformationForm.Header")}</StackItem>
      <StackItem className="fields">
        <Stack className="field">
          <StackItem className="label">
            {t("UserInformationForm.UserInformation.FirstName")}
            <span className="imp">*</span>
          </StackItem>
          <StackItem className="value">
            <TextField
              autoComplete="off"
              placeholder={t("UserInformationForm.UserInformation.FirstNamePlaceholder")}
              value={_userOptions.FirstName}
              onChange={(ev, text) => {
                _onChangeText(ev, text, UserFieldEnum.FirstName);
              }}
            ></TextField>
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">
            {t("UserInformationForm.UserInformation.LastName")}
            <span className="imp">*</span>
          </StackItem>
          <StackItem className="value">
            <TextField
              autoComplete="off"
              placeholder={t("UserInformationForm.UserInformation.LastNamePlaceholder")}
              value={_userOptions.LastName}
              onChange={(ev, text) => {
                _onChangeText(ev, text, UserFieldEnum.LastName);
              }}
            ></TextField>
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">
            {t("UserInformationForm.UserInformation.Email")}
            <span className="imp">*</span>
          </StackItem>
          <StackItem className="value">
            <TextField
              autoComplete="off"
              placeholder={t("UserInformationForm.UserInformation.EmailPlaceholder")}
              value={_userOptions.Email}
              onChange={(ev, text) => {
                _onChangeText(ev, text, UserFieldEnum.Email);
              }}
            ></TextField>
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">
            {t("UserInformationForm.UserInformation.HiringDate")}
            <span className="imp">*</span>
          </StackItem>
          <StackItem className="value">
            <AppCalendar
              IsEditable={true}
              InitialDate={_userOptions.HiringDate ? moment(_userOptions.HiringDate).toDate() : null}
              GetCalendatDate={function (date: Date): void {
                const tempUserOptions = JSON.parse(JSON.stringify(_userOptions));
                tempUserOptions.HiringDate = date ?? null;
                _setUserOptions(tempUserOptions);
                setHiringDate(date);
              }}
            />
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">
            {t("UserInformationForm.UserInformation.Password")}
            <span className="imp">*</span>
          </StackItem>
          <StackItem className="value">
            <TextField
              autoComplete="off"
              placeholder={t("UserInformationForm.UserInformation.PasswordPlaceholder")}
              value={_userOptions.Password}
              onChange={(ev, text) => {
                _onChangeText(ev, text, UserFieldEnum.Password);
              }}
            ></TextField>
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">{t("UserInformationForm.UserInformation.Status")}</StackItem>
          <StackItem className="value">
            <Dropdown
              calloutProps={{ directionalHintFixed: true }}
              onChange={_onStatusChange}
              placeholder={t("UserInformationForm.UserInformation.StatusPlaceholder")}
              options={_statusOptions}
              selectedKey={_userOptions.Status}
              disabled={
                originalUserValues
                  ? originalUserValues.Status == AppConstants.UserFilterOptions.Draft
                    ? true
                    : false
                  : _userOptions.Status == AppConstants.UserFilterOptions.Draft
                  ? true
                  : false
              }
            />
          </StackItem>
        </Stack>
        <Stack className="field">
          <StackItem className="label">
            <Checkbox
              label={t("UserInformationForm.UserInformation.ValidationStatusLabel")}
              boxSide="end"
              onChange={_onValidationChange}
              checked={_userOptions?.Validated ?? false}
            />
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem className="submitSec">
        <Stack>
          <StackItem className="submitSecRow">
            <DefaultButton className="cancelBTN" text={t("UserInformationForm.CancelButton")} onClick={props.cancelPanel} allowDisabledFocus />
            <DefaultButton className="submitBTN" text={t("UserInformationForm.SaveButton")} onClick={submitForm} allowDisabledFocus />
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};
