export enum ValidationType {
  FileOnly,
  All,
  FieldOnly,
}

export enum AdditionalFormDLLEnum {
  Nationality,
  RaceColor,
  BCountry,
  ACountry,
  MaritalStatus,
  EducationLevel,
}
export enum BasicFormTXTEnum {
  FatherName,
  FullName,
  MotherName,
}
export enum AdditionalFormTXTEnum {
  Telephone,
  Email,
  BState,
  BCity,
  ACEP,
  AState,
  ACity,
  ADistrict,
  APublicArea,
  AAddress,
  ANumber,
  AComplement,
}
export enum Document1FormTXTEnum {
  CPFNo,
  NISNo,
  CTPSNo,
  CTPSSerie,
  CTPSDigit,
  CTPSState,
  PassportNo,
  PIAuthority,
  PEState,
  NoFVC,
  NoFRN,
  NoIAuthority,
  IDNumber,
  IssuimgAuthority,
  EmissionState,
}
export enum Document2FormTXTEnum {
  VRNo,
  VRElectoralZone,
  VRSection,
  VRState,
  VRCity,
  CNHNo,
  CNHLCategory,
  CNHIAuthority,
  CNHEState,
  MilitryCNo,
  MilitarySerie,
  MilitaryRANo,
  BankAgency,
  BankAType,
  BankANo,
  BankDigit,
}
export enum AdminPanelFormEnum {
  UserProfile,
  NewUserForm,
  EditUserForm,
  None,
}
export enum DependentUserFormDLLEnum {
  Relationship,
  Gender,
  MaritalStatus,
}
export enum DependentUserFormTXTEnum {
  FullName,
  MotherName,
  CPFNumber,
  IDNumber,
  IssuimgAuthority,
  EmissionState,
}
export enum SaveTypeEnum {
  BackSave,
  NextSave,
  Back,
  Next,
  Submit,
}
export enum UserFieldEnum {
  FirstName,
  LastName,
  Email,
  Password,
  Status,
}
export enum ProfileView {
  User,
  Admin,
  AdminUser,
}
export enum CommentMode {
  Edit,
  ViewAll,
}

export enum EventMode {
  Add,
  Edit,
  Blank,
}
export enum TimeMode {
  StartHours,
  StartMins,
  EndHours,
  EndMins,
}
export let FreelanceConstants = {
  mediaQry: "480px",
};
